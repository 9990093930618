import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import queryString from 'query-string'
import { saveAsyncStorage, fetchStaff, fetchOrg, fetchVersion, authenticate, makeLoginError } from '../api/actions'
import { fetchStaffByMailAddress, fetchStaffByStaffId, initAWS } from '../api/middleware' // middlewareを直接呼び出しているのは良くない。いつか直す
import { DOMAIN, BASE, IDENTITY_POOL_ID, REGION, API_ENDPOINT, API_KEY } from '../constant'
import LoginLayout from '../layouts/Login'

const { useState, useEffect } = React

const Login = ({
  saveAsyncStorage,
  fetchStaff,
  fetchOrg,
  fetchVersion,
  authenticate,
  makeLoginError,
  location,
  history
}) => {
  const [isMounting, toggleMounting] = useState(true)
  const [isLoggingIn, toggleLoggingIn] = useState(false)
  const [isLoginError, toggleLoginError] = useState(false)
  const [identityId, setIdentityId] = useState('')

  useEffect(() => {
    async function didMount() {
      const identityId = await initAWS(REGION, IDENTITY_POOL_ID, DOMAIN, BASE)
      setIdentityId(identityId)

      const parsedQuery = queryString.parse(location.search)
      const id = parsedQuery.id

      if (id) return onAuthenticate(id)

      toggleMounting(false)
    }
    didMount()
  }, [])

  const onLogin = async staffInfo => {
    toggleLoggingIn(true)

    const staffQueriedByStaffId = await fetchStaffByStaffId(staffInfo)
    const staffQueriedByMailAddress = await fetchStaffByMailAddress(staffInfo)
    const id = staffQueriedByStaffId
      ? staffQueriedByStaffId.id
      : staffQueriedByMailAddress
        ? staffQueriedByMailAddress.id
        : null

    if (id) return onAuthenticate(id)

    toggleLoginError(true)
    toggleLoggingIn(false)
  }

  const onAuthenticate = async id => {
    const bucket = `content.${DOMAIN}.${BASE}`

    await saveAsyncStorage({
      domain: DOMAIN,
      identityPoolId: IDENTITY_POOL_ID,
      region: REGION,
      id,
      identityId,
      apiEndpoint: API_ENDPOINT,
      apiKey: API_KEY,
      bucket,
      firstTime: true
    })

    const isExisting = await fetchStaff()
    if (isExisting) {
      await fetchOrg()
      await fetchVersion()
      await authenticate()
      toggleMounting(false)
      toggleLoggingIn(false)
      history.push('/list?id=' + id)
    } else {
      makeLoginError()
      toggleMounting(false)
      toggleLoggingIn(false)
      history.push('/error')
    }
  }

  return <LoginLayout onLogin={onLogin} isMounting={isMounting} isLoggingIn={isLoggingIn} isLoginError={isLoginError} />
}

export default withRouter(
  connect(
    null,
    {
      saveAsyncStorage,
      fetchStaff,
      fetchOrg,
      fetchVersion,
      authenticate,
      makeLoginError
    }
  )(Login)
)

/* PropTypes */
Login.propTypes = {
  saveAsyncStorage: PropTypes.func.isRequired,
  fetchStaff: PropTypes.func.isRequired,
  fetchOrg: PropTypes.func.isRequired,
  fetchVersion: PropTypes.func.isRequired,
  authenticate: PropTypes.func.isRequired,
  makeLoginError: PropTypes.func.isRequired
}
