import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import QrHeader from '../components/Qr/QrHeader'
import QrBody from '../components/Qr/QrBody'

const Qr = ({ url, code, goBack, isSmallWidth }) => {
  const styles = {
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    }
  }

  return (
    <div style={styles.wrapper}>
      <QrHeader goBack={goBack} isSmallWidth={isSmallWidth} />
      <QrBody url={url} code={code} isSmallWidth={isSmallWidth} />
    </div>
  )
}

const mapStateToProps = state => ({
  isSmallWidth: state.layout.isSmallWidth
})

export default connect(mapStateToProps)(Qr)

/* PropTypes */
Qr.propTypes = {
  url: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
  goBack: PropTypes.func.isRequired,
  isSmallWidth: PropTypes.bool.isRequired
}
