import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import Dialog from '../Common/Dialog'
import { clearError } from '../../api/actions'

const { useState, useEffect } = React

// エラーメッセージを表示するためのコンポーネント
const ErrorDialog = ({ error, clearError, t }) => {
  const [errorMessage, changeErrorMessage] = useState('')
  const [errorDialogOpen, toggleErrorDialog] = useState(false)

  const errorMessages = {
    maximumLimit: t('maximumLimit')
  }

  useEffect(
    () => {
      if (error.contentsError) {
        changeErrorMessage(errorMessages[error.contentsError])
        toggleErrorDialog(true)
      }
    },
    [error]
  )

  const closeErrorDialog = () => {
    toggleErrorDialog(false)
    clearError()
  }

  return <Dialog text={errorMessage} open={errorDialogOpen} onClick={() => closeErrorDialog()} />
}

const mapStateToProps = state => ({ error: state.error })

export default connect(
  mapStateToProps,
  { clearError }
)(withTranslation()(ErrorDialog))

/* PropTypes */
ErrorDialog.propTypes = {
  error: PropTypes.object.isRequired,
  clearError: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
}
