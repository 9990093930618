import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import qrcode from 'qrcode'
import '../i18n'
import { authCode } from '../api/middleware' // middlewareを直接呼び出しているのは良くない。いつか直す
import QrLayout from '../layouts/Qr'

const { useState, useEffect } = React

const Qr = ({ profile, storage, history, location }) => {
  const [code, setCode] = useState('')
  const [url, setUrl] = useState('')

  useEffect(() => {
    async function didMount() {
      const { apiEndpoint, apiKey } = await storage
      const code = authCode(profile.id, profile.orgId, apiEndpoint, apiKey)
      setCode(code)
      const option = {
        errorCorrectionLevel: 'H',
        type: 'image/jpeg',
        rendererOpts: {
          quality: 0.3
        }
      }
      const url = await qrcode.toDataURL(code, option)
      setUrl(url)
    }
    didMount()
  }, [])

  const goBack = () => history.push('/list' + location.search)

  return <QrLayout url={url} code={code} goBack={goBack} />
}

const mapStateToProps = state => {
  return {
    profile: state.profile,
    storage: state.storage
  }
}

export default withRouter(connect(mapStateToProps)(Qr))

/* PropTypes */
Qr.propTypes = {
  profile: PropTypes.object.isRequired,
  storage: PropTypes.object.isRequired
}
