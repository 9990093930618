import url from 'url'
import * as R from 'ramda'
import Promise from 'bluebird'
import i18next from 'i18next'
import database from '../repository/database'
import storage from '../repository/storage'
import { initializeAwsSettings } from '../repository/repository'

const Buffer = require('buffer/').Buffer

const showError = err => {
  const msg = `${i18next.t('errorOccurred')}\n${err}`
  alert(msg)
  throw err
}

export const initAWS = (region, identityPoolId, domain, base) => {
  return initializeAwsSettings(region, identityPoolId, domain, base).catch(showError)
}

export const fetchVersion = () => {
  return database
    .findOrgById(1)
    .then(R.prop('version'))
    .catch(showError)
}

export const fetchOrg = id => {
  return database.findOrgById(id).catch(showError)
}

export const fetchStaff = id => {
  return database.findStaffById(id).catch(showError)
}

export const fetchStaffByStaffId = no => {
  return database.findStaffByStaffId(no).catch(showError)
}

export const fetchStaffByMailAddress = mail => {
  return database.findStaffByMailAddress(mail).catch(showError)
}

export const fetchDeliveriesByOrgId = async orgId => {
  try {
    const deliveriesOfRootOrg = await database.findDeliveriesByOrgId(1, orgId)
    const deliveriesOfOwnOrg = await database.findDeliveriesByOrgId(orgId, orgId)
    return [...deliveriesOfRootOrg, ...deliveriesOfOwnOrg]
  } catch (err) {
    showError(err)
  }
}

export const fetchContentsById = id => {
  return database.findContentById(id).catch(showError)
}

export const updateStaffsLanguage = (id, lang) => {
  return database.updateStaffItem(id, { lang }).catch(showError)
}

export const seed = (url, orgId, key) => {
  return fetch(`${url}?orgId=${orgId}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Api-Key': key
    }
  })
    .then(res => res.json())
    .catch(showError)
}

export const fetchTestResults = (contentIds, staffId) => {
  return Promise.map(contentIds, contentId => database.findTestResultByContentIdAndStaffId(contentId, staffId))
    .then(R.flatten)
    .catch(showError)
}

export const addOpenedContent = (staffId, orgId, contentId) => {
  const item = { staffId, orgId, contentId }
  return database.createAccessLog(item).catch(showError)
}

export const getContentViewCount = id => {
  return database
    .findContentById(id)
    .then(({ count }) => R.defaultTo(0, count)) // countがundefinedの時（閲覧されていない）は0を返す
    .catch(showError)
}

export const addContentViewCount = id => {
  return database.addCount(id, 1).catch(showError)
}

export const confirmContent = async (domain, apiEndpoint, staffId, deliveryId, contentId, path) => {
  // スタッフ情報をフェッチして、最新の確認済みコンテンツを取得。まだ確認していなかったら、結果を送信する
  // マイページ・スマホアプリを同時に開いて、両者から結果送信しようとした時に、同じ結果が二重に記録されてしまうのを防ぐ
  const { contentIds } = await fetchStaff(staffId).catch(showError)
  const alreadyDone = R.includes(contentId, contentIds)

  // 既にスマホアプリから確認済みだったら、結果送信せず、OKを返す
  if (alreadyDone) return { ok: true }

  return fetch(`${apiEndpoint}confirm-content`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ staffId, path, deliveryId, contentId })
  }).catch(showError)
}

export const getContentUri = async (contentId, purpose, bucket, staffId) => {
  try {
    if (purpose === 'manual') {
      return `https://${bucket}/list?cid=${contentId}&sid=${staffId}`
    }

    if (purpose === 'storm') {
      return `https://${bucket}/${contentId}/sco/index.html`
    }

    if (purpose === 'exercise' || purpose === 'exam' || purpose === 'questionnaire') {
      return `https://${bucket}/${contentId}/sco/index.html?content_id=${contentId}&user_id=${staffId}`
    }

    if (purpose === 'pdf') {
      const baseUrl = `https://${bucket}/${contentId}/index.html`

      const pdfUrl = await storage.getFileUrlFromContentStorage(`${contentId}/index.pdf`)
      const signature = R.prop('query', url.parse(pdfUrl))
      const queryParameter = `sign=${encodeURIComponent(signature)}`

      return `${baseUrl}?${queryParameter}`
    }

    return `https://${bucket}/${contentId}/index.html`
  } catch (err) {
    showError(err)
  }
}

const base64encode = str => {
  const base64str = Buffer.alloc(str.length, str).toString('base64')
  return base64str
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=/g, '')
}

export const authCode = (id, orgId, apiEndpoint, apiKey) => base64encode(`${apiEndpoint}seed,${orgId},${apiKey},${id}`)
