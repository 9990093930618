import * as R from 'ramda'
import db from './database'

const tableName = 'DELIVERY'

const findDeliveriesByOrgId = (orgId, target) => {
  const params = {
    IndexName: 'orgId-index',
    KeyConditionExpression: '#o = :o',
    FilterExpression: '(#s = :s1 OR (#s = :s2 AND #p = :p) OR #s = :s3) AND contains(#t, :t)',
    ExpressionAttributeNames: {
      '#o': 'orgId',
      '#s': 'status',
      '#p': 'preShow',
      '#t': 'target'
    },
    ExpressionAttributeValues: {
      ':o': orgId,
      ':s1': 'sent',
      ':s2': 'pending',
      ':s3': 'archive',
      ':p': true,
      ':t': target
    },
    ScanIndexForward: false
  }

  return db.fetchItems({ table: tableName, params }).then(R.prop('items'))
}

export default {
  findDeliveriesByOrgId
}
