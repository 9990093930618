import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import CloseIcon from '@material-ui/icons/Close'
import Modal from '@material-ui/core/Modal'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import { withTranslation } from 'react-i18next'
import appStoreBanner from '../../images/app_store.png'
import googlePlayBanner from '../../images/google_play.png'
import appStoreQr from '../../images/sp_appstore.png'
import googlePlayQr from '../../images/sp_googleplay.png'
import description from '../../images/app_description01.png'
import description2 from '../../images/app_description02.png'
import { COLOR } from '../../constant'

export const AppsDescription = ({ modalOpen, closeModal, isSmallWidth, t }) => {
  const styles = {
    container: {
      backgroundColor: 'white',
      overflow: 'scroll',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: isSmallWidth ? '100%' : '80%',
      maxHeight: isSmallWidth ? '100%' : '80%',
      padding: isSmallWidth ? 5 : 20,
      paddingTop: isSmallWidth ? 70 : 0
    },
    closeButton: {
      position: 'fixed',
      top: isSmallWidth ? 0 : 'calc(10% - 40px)',
      right: isSmallWidth ? 0 : 'calc(10% - 40px)',
      zIndex: 10000
    },
    listItem: {
      flexDirection: 'column',
      alignItems: isSmallWidth ? 'center' : 'start',
      marginBottom: 20,
      width: '100%'
    },
    download: {
      display: 'flex',
      flexDirection: isSmallWidth ? 'column' : 'row',
      marginTop: 10,
      item: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: isSmallWidth ? null : COLOR.LIGHT,
        padding: 20,
        marginRight: 20
      },
      banner: { marginRight: isSmallWidth ? 0 : 20 },
      qr: { display: isSmallWidth ? 'none' : null }
    },
    description: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: isSmallWidth ? 'column' : 'row',
      width: '100%',
      marginTop: 10,
      image: { width: isSmallWidth ? '60%' : null }
    }
  }

  return (
    <Modal open={modalOpen}>
      <div>
        <Button variant="fab" mini color="secondary" onClick={closeModal} style={styles.closeButton}>
          <CloseIcon />
        </Button>

        <List style={styles.container}>
          <ListItem style={styles.listItem}>
            <div>{t('howToInstall1')}</div>
            <div style={styles.download}>
              <div style={styles.download.item}>
                <a href="https://apps.apple.com/jp/app/id1254313295" target="_blank" rel="noopener noreferrer">
                  <img style={styles.download.banner} src={appStoreBanner} />
                </a>
                <img style={styles.download.qr} src={appStoreQr} />
              </div>
              <div style={styles.download.item}>
                <a
                  href="https://play.google.com/store/apps/details?id=com.spottynative"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img style={styles.download.banner} src={googlePlayBanner} />
                </a>
                <img style={styles.download.qr} src={googlePlayQr} />
              </div>
            </div>
          </ListItem>
          <ListItem style={styles.listItem}>
            <div>{t('howToInstall2')}</div>
            <div style={styles.description}>
              <img style={styles.description.image} src={description} />
              <ul>
                <li>{t('howToInstall2-1')}</li>
                <li>{t('howToInstall2-2')}</li>
                <li>{t('howToInstall2-3')}</li>
              </ul>
            </div>
          </ListItem>
          <ListItem style={styles.listItem}>
            <div>{t('howToInstall3')}</div>
            <div style={styles.description}>
              <img style={styles.description.image} src={description2} />
            </div>
          </ListItem>
        </List>
      </div>
    </Modal>
  )
}

const QrBody = ({ url, code, isSmallWidth, t }) => {
  const [modalOpen, toggleModalOpen] = useState(false)

  const styles = {
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '80%',
      paddingBottom: 30
    },
    code: { width: '100%', wordWrap: 'break-word', marginBottom: 20 }
  }

  return (
    <div style={styles.wrapper}>
      <img src={url} alt="QR code" />
      <Typography variant="subheading" style={styles.code}>
        {code}
      </Typography>
      <Button variant="outlined" color="secondary" onClick={() => toggleModalOpen(true)}>
        {t('howToInstall')}
      </Button>

      <AppsDescription
        modalOpen={modalOpen}
        closeModal={() => toggleModalOpen(false)}
        isSmallWidth={isSmallWidth}
        t={t}
      />
    </div>
  )
}

export default withTranslation()(QrBody)

/* PropTypes */
AppsDescription.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  isSmallWidth: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired
}

QrBody.propTypes = {
  url: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
  isSmallWidth: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired
}
