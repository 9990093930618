import * as R from 'ramda'
import Promise from 'bluebird'
import axios from 'axios'
import { createStorageConnection, BUCKETS } from '../repository'

const getFile = (bucket, key) => {
  const storage = createStorageConnection(BUCKETS[bucket])
  const params = { Key: key }

  return Promise.resolve()
    .then(() => storage.getSignedUrlPromise('getObject', params))
    .then(axios.get)
    .then(R.prop('data'))
    .catch(err => {
      if (!err.message.includes('404')) throw new Error(err.message)
    })
}

const getPermissionStatusOfFile = (bucket, key) => {
  const storage = createStorageConnection(BUCKETS[bucket])
  const params = { Key: key }

  return storage
    .getObjectAcl(params)
    .promise()
    .then(R.prop('Grants'))
    .then(
      R.compose(
        R.prop('Permission'),
        R.defaultTo({}),
        R.head,
        R.filter(R.pathEq(['Grantee', 'Type'], 'Group'))
      )
    )
}

const getFileUrl = (bucket, key) => {
  const storage = createStorageConnection(BUCKETS[bucket])
  const params = { Key: key }

  return (
    getPermissionStatusOfFile(bucket, params.Key)
      .then(async permissionStatus => {
        const url = await storage.getSignedUrlPromise('getObject', params)

        // パブリックアクセス可能だったら、署名のパラメータを取り除く
        const isPublicRead = R.either(R.equals('READ'), R.equals('FULL_CONTROL'))
        if (isPublicRead(permissionStatus)) {
          return R.compose(
            R.head,
            R.split('?')
          )(url)
        }
        return url
      })
      // s3ドメインを削除
      .then(url => 'https://' + R.last(R.split('s3.ap-northeast-1.amazonaws.com/', url)))
  )
}

const putFile = (bucket, acl, file, key, mime) => {
  const storage = createStorageConnection(BUCKETS[bucket])

  // ファイルサイズ等を取るため、fileが文字列だったらBlobに変換
  const blob = typeof file === 'string' ? new Blob([file], { type: mime }) : file

  // ファイルをchunkに分割して、並列でアップロードする
  const fileSize = blob.size
  const chunkSize = 5 * 1024 * 1024
  const numOfChunks = Math.ceil(fileSize / chunkSize)

  const devideFileToChunks = num => {
    const start = num * chunkSize
    const end = num + 1 !== numOfChunks ? (num + 1) * chunkSize : fileSize
    return blob.slice(start, end)
  }

  const chunks = R.times(devideFileToChunks, numOfChunks)

  // アップロードIDを作成
  const createUploadId = () => {
    const params = {
      Key: key,
      CacheControl: 'no-cache, no-store',
      ContentType: mime,
      ACL: acl
    }

    return storage
      .createMultipartUpload(params)
      .promise()
      .then(R.prop('UploadId'))
  }

  // chunkを並列アップロード
  const uploadContent = async (chunk, partNumber, uploadId) => {
    const params = {
      Body: chunk,
      Key: key,
      PartNumber: partNumber,
      UploadId: uploadId
    }

    const partUpload = await storage.uploadPart(params).promise()
    return { ETag: R.prop('ETag', partUpload), PartNumber: partNumber }
  }

  // アップロードの完了処理。これをやらないと、ファイルが上がらない。
  const completeUpload = (uploadId, parts) => {
    const params = {
      Key: key,
      MultipartUpload: { Parts: parts },
      UploadId: uploadId
    }

    return storage.completeMultipartUpload(params).promise()
  }

  const upalodFiles = async () => {
    const uploadId = await createUploadId()
    const partsUploaded = await Promise.map(chunks, (chunk, index) => uploadContent(chunk, index + 1, uploadId))
    return completeUpload(uploadId, partsUploaded)
  }

  return Promise.resolve().then(upalodFiles)
}

const deleteFile = (bucket, key) => {
  const storage = createStorageConnection(BUCKETS[bucket])
  const params = { Key: key }
  return storage.deleteObject(params).promise()
}

const deleteMultipleFiles = (bucket, keys) => {
  const storage = createStorageConnection(BUCKETS[bucket])

  const objects = R.map(R.objOf('Key'), keys)
  const params = {
    Delete: { Objects: objects }
  }

  return storage.deleteObjects(params).promise()
}

const copyFile = (bucket, acl, source, key) => {
  const storage = createStorageConnection(BUCKETS[bucket])

  const params = {
    ACL: acl,
    CopySource: `${BUCKETS[bucket]}/${source}`,
    Key: key,
    CacheControl: 'no-cache, no-store'
  }

  return storage.copyObject(params).promise()
}

const copyDirectory = async (bucket, acl, sourceDirectory, targetDirectory) => {
  const files = await listFiles(bucket, sourceDirectory)
  const keys = R.pluck('Key', files)

  return Promise.map(keys, key => {
    const filename = R.compose(
      R.head,
      R.tail,
      R.split('/')
    )(key)
    return copyFile(bucket, acl, key, `${targetDirectory}/${filename}`)
  })
}

const listFiles = (bucket, prefix) => {
  const storage = createStorageConnection(BUCKETS[bucket])

  const listObjects = async (token = null, hasNext = true, accumulator = []) => {
    if (hasNext) {
      const params = {
        Prefix: prefix,
        ContinuationToken: token,
        MaxKeys: 1000 // defaultおよび最大値も1000
      }

      const { NextContinuationToken, IsTruncated, Contents } = await storage.listObjectsV2(params).promise()

      const acc = R.concat(accumulator, Contents)
      // IsTruncatedがfalseになるまで再帰的に呼び出す
      return listObjects(NextContinuationToken, IsTruncated, acc)
    }

    return accumulator
  }

  return Promise.resolve().then(listObjects)
}

const setAcl = (acl, bucket, keys) => {
  const storage = createStorageConnection(BUCKETS[bucket])

  const putObjectAcl = key => {
    const params = { Key: key, ACL: acl }
    return storage.putObjectAcl(params).promise()
  }

  return Promise.map(keys, putObjectAcl)
}

export default {
  getFile,
  getFileUrl,
  putFile,
  deleteFile,
  deleteMultipleFiles,
  copyFile,
  copyDirectory,
  listFiles,
  setAcl
}
