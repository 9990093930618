import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import * as R from 'ramda'
import '../i18n'
import DeliveryLayout from '../layouts/Delivery'
import { sentDeliveries, archiveDeliveries } from '../api/reducers'
import { fetchDeliveries, fetchContents, fetchTestResults, changeStatus, changeCategory } from '../api/actions'

const useDeliveries = fetchDeliveries => {
  useEffect(() => {
    const didMount = () => fetchDeliveries()
    didMount()
  }, [])
}

const useContents = (deliveries, fetchContents) => {
  useEffect(
    () => {
      if (R.isEmpty(deliveries)) return

      const findContents = () => {
        const contentIds = R.flatten(R.values(R.pluck('contents', deliveries)))
        return fetchContents({ ids: contentIds })
      }
      findContents()
    },
    [deliveries]
  )
}

const useTestResults = (contents, fetchTestResults) => {
  useEffect(
    () => {
      if (R.isEmpty(contents)) return

      const findTestResults = () => fetchTestResults()
      findTestResults()
    },
    [contents]
  )
}

const useTestResultsAddedToContentList = (contents, result) => {
  const [contentList, setContentList] = useState({})

  // テスト結果を各々のコンテンツに加える
  useEffect(() => {
    // コンテンツが空なら、以下の作業は意味がないのでリターン
    if (R.isEmpty(contents)) return

    const idsOfTestContents = R.uniq(R.pluck('cid', result))

    const contentListWithTestResult = idsOfTestContents.map(contentId => {
      const content = contents[contentId]
      const resultsOfContent = R.filter(R.propEq('cid', contentId), result)

      // 試験の場合は、最初の結果を加える
      if (content.purpose === 'exam') {
        const firstResult = R.find(R.propEq('n', 1), resultsOfContent)
        const { sco, max, pass } = firstResult
        return { ...content, sco, max, pass }
      }
      // 習得チェックの場合は、最も良い結果を加える
      else if (content.purpose === 'exercise') {
        const bestResult = R.head(R.sort(R.descend(R.prop('sco')), resultsOfContent))
        const { sco, max, pass } = bestResult
        return { ...content, sco, max, pass }
      }
    })

    const contentsAddedTestResult = R.reduce(
      (accumulator, content) => R.assoc(content.id, content, accumulator),
      contents,
      contentListWithTestResult
    )

    setContentList(contentsAddedTestResult)
  })

  return contentList
}

const DeliveryPage = ({
  staffName,
  orgName,
  selectedStatus,
  categoryList,
  selectedCategoryId,
  deliveries,
  sentDeliveries,
  archiveDeliveries,
  contents,
  result,
  version,
  fetchDeliveries,
  fetchContents,
  fetchTestResults,
  changeStatus,
  changeCategory
}) => {
  const [isRefresh, toggleRefresh] = useState(false)
  const [allListsOpened, toggleAllLists] = useState(true)
  const [drawerOpen, toggleDrawer] = useState(false)

  // マウント時に配信を取得
  useDeliveries(fetchDeliveries)

  // 配信を取得し終えたら、そこに紐付いているコンテンツを取得
  useContents(deliveries, fetchContents)

  // コンテンツを取得し終えたら、テスト結果を取得
  useTestResults(contents, fetchTestResults)

  const contentsWithResult = useTestResultsAddedToContentList(contents, result)

  const refresh = async () => {
    toggleRefresh(true)
    await fetchDeliveries()
    toggleRefresh(false)
  }

  const onChangeStatus = status => {
    changeStatus({ status })
    toggleDrawer(false)
  }

  const onChangeCategory = categoryId => {
    changeCategory({ categoryId })
    toggleDrawer(false)
  }

  return (
    <DeliveryLayout
      isRefresh={isRefresh}
      allListsOpened={allListsOpened}
      toggleAllLists={toggleAllLists}
      drawerOpen={drawerOpen}
      refresh={refresh}
      onChangeStatus={onChangeStatus}
      onChangeCategory={onChangeCategory}
      openDrawer={() => toggleDrawer(true)}
      closeDrawer={() => toggleDrawer(false)}
      staffName={staffName}
      orgName={orgName}
      sentDeliveries={sentDeliveries}
      archiveDeliveries={archiveDeliveries}
      selectedStatus={selectedStatus}
      categoryList={categoryList}
      contents={contentsWithResult}
      selectedCategoryId={selectedCategoryId}
      version={version}
    />
  )
}

const mapStateToProps = state => ({
  staffName: state.profile.name,
  orgName: state.org.name,
  selectedStatus: state.app.selectedStatus,
  categoryList: state.delivery.categoryList,
  selectedCategoryId: state.app.selectedCategoryId,
  deliveries: state.delivery.records,
  sentDeliveries: sentDeliveries(state),
  archiveDeliveries: archiveDeliveries(state),
  contents: state.content.records,
  result: state.result,
  version: state.version
})

export default withRouter(
  connect(
    mapStateToProps,
    { fetchDeliveries, fetchContents, fetchTestResults, changeStatus, changeCategory }
  )(DeliveryPage)
)

/* PropTypes */
DeliveryPage.propTypes = {
  staffName: PropTypes.string.isRequired,
  orgName: PropTypes.string.isRequired,
  selectedStatus: PropTypes.string.isRequired,
  selectedCategoryId: PropTypes.number.isRequired,
  deliveries: PropTypes.object.isRequired,
  sentDeliveries: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired
    }).isRequired
  ).isRequired,
  archiveDeliveries: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired
    }).isRequired
  ).isRequired,
  categoryList: PropTypes.array.isRequired,
  contents: PropTypes.object.isRequired,
  result: PropTypes.array.isRequired,
  version: PropTypes.string.isRequired,
  fetchDeliveries: PropTypes.func.isRequired,
  fetchContents: PropTypes.func.isRequired,
  fetchTestResults: PropTypes.func.isRequired,
  changeStatus: PropTypes.func.isRequired,
  changeCategory: PropTypes.func.isRequired
}
