import React, { Component } from 'react'
import { Provider } from 'react-redux'
import configureStore from './configureStore'
import ThemeProvider from './ThemeProvider'

export default class Root extends Component {
  constructor() {
    super()
    this.state = {
      isLoading: false,
      store: configureStore(() => this.setState({ isLoading: false }))
    }
  }

  render() {
    return (
      <Provider store={this.state.store}>
        <ThemeProvider />
      </Provider>
    )
  }
}
