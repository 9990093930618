import * as R from 'ramda'
import Promise from 'bluebird'
import { createDatabaseConnection, TABLES } from '../repository'

const isNotNil = R.complement(R.isNil)

const fetchItems = ({ table, params, lastKeyOfPreviousFetch, limit, delay = 0 }) => {
  const database = createDatabaseConnection(TABLES[table])

  const fetchLoop = async (lastKeyOfPreviousLoop, lim = limit, hasNext = true, accumulator = []) => {
    params.ExclusiveStartKey = lastKeyOfPreviousLoop || lastKeyOfPreviousFetch
    params.Limit = lim

    if (hasNext) {
      const { LastEvaluatedKey, Items } = await database.query(params).promise()
      const acc = R.concat(accumulator, Items)

      await Promise.delay(delay)
      const lastKey = LastEvaluatedKey
      const remainingLimit = limit - acc.length
      return fetchLoop(lastKey, remainingLimit, remainingLimit !== 0 && isNotNil(lastKey), acc)
    } else {
      const existsNextItem = await confirmNextKeyExistence(table, params, lastKeyOfPreviousLoop)
      const LastEvaluatedKey = existsNextItem ? lastKeyOfPreviousLoop : null
      return { items: accumulator, lastKeyOfFetch: LastEvaluatedKey }
    }
  }

  // Promise.resolveしておかないと、後続処理でbluebirdが使えない
  return Promise.resolve().then(fetchLoop)
}

const confirmNextKeyExistence = async (table, params, startKey) => {
  const database = createDatabaseConnection(TABLES[table])

  // そもそもstartKeyがundefinedなら次のレコードはなし
  if (!startKey) return false
  // Limit＝レコード数の時（ex. Limit=100、レコード100件）、LastEvaluatedKeyでは次のキーがあるのかどうか判定できない
  // （100件中の100件目がLastEcaluatedKeyに入ってくるため）
  // そのため、次の一件を読み込み、LastEvaluatedKeyがundefinedかどうかで、次のキーがあるのか判定する
  else {
    params.Limit = 1
    params.ExclusiveStartKey = startKey
    const { LastEvaluatedKey } = await database.query(params).promise()
    return isNotNil(LastEvaluatedKey)
  }
}

const fetchAllItems = table => {
  const database = createDatabaseConnection(TABLES[table])

  return database
    .scan()
    .promise()
    .then(R.prop('Items'))
}

const getItem = (table, params) => {
  const database = createDatabaseConnection(TABLES[table])

  return database
    .query(params)
    .promise()
    .then(
      R.compose(
        R.head,
        R.prop('Items')
      )
    )
}

const createItem = (table, params) => {
  const database = createDatabaseConnection(TABLES[table])

  // null, undefined, 空文字を登録しようとするとエラーになる（空の配列はOK）ので、ここで取り除く
  const isNilOrEmptyString = R.either(R.isNil, R.both(R.is(String), R.isEmpty))
  params.Item = R.reject(isNilOrEmptyString, params.Item)

  return database
    .put(params)
    .promise()
    .then(() => params.Item)
}

const updateItem = (table, key, item) => {
  const database = createDatabaseConnection(TABLES[table])

  // valueがundefinedな項目は消す
  const itemRemovedNil = R.filter(R.complement(R.isNil), item)

  // パラメータを作成
  const params = {
    Key: key,
    UpdateExpression: 'set ',
    ReturnValues: 'ALL_NEW',
    ExpressionAttributeNames: {},
    ExpressionAttributeValues: {}
  }

  const itemArray = R.toPairs(itemRemovedNil)
  itemArray.forEach(([key, value], index) => {
    params.UpdateExpression += `#key${index + 1} = :value${index + 1}`
    if (itemArray.length !== index + 1) params.UpdateExpression += ', '

    params.ExpressionAttributeNames[`#key${index + 1}`] = key
    params.ExpressionAttributeValues[`:value${index + 1}`] = value
  })

  return database
    .update(params)
    .promise()
    .then(R.prop('Attributes'))
}

const deleteItem = (table, key) => {
  const database = createDatabaseConnection(TABLES[table])

  const params = {
    Key: key,
    ReturnValues: 'ALL_OLD'
  }

  // メモ：存在しないアイテムを削除しようとしてもエラーはでない
  return database
    .delete(params)
    .promise()
    .then(R.prop('Attributes'))
}

const deleteAttributesFromItem = (table, key, items) => {
  const database = createDatabaseConnection(TABLES[table])

  const params = {
    Key: key,
    ExpressionAttributeNames: {},
    UpdateExpression: 'remove ',
    ReturnValues: 'ALL_NEW'
  }

  // 更新式を作成
  items.forEach((item, index) => {
    params.ExpressionAttributeNames[`#remove${index + 1}`] = item
    params.UpdateExpression += `#remove${index + 1}`
    if (items.length !== index + 1) params.UpdateExpression += ', '
  })

  return database
    .update(params)
    .promise()
    .then(R.prop('Attributes'))
}

const appendListItem = (table, key, name, values) => {
  const database = createDatabaseConnection(TABLES[table])

  // パラメータを作成
  const params = {
    Key: key,
    UpdateExpression: 'set #name = list_append(#name, :values)',
    ReturnValues: 'ALL_NEW',
    ExpressionAttributeNames: { '#name': name },
    ExpressionAttributeValues: { ':values': values }
  }

  return database
    .update(params)
    .promise()
    .then(R.prop('Attributes'))
}

const removeListItem = (table, key, name, index) => {
  const database = createDatabaseConnection(TABLES[table])

  // パラメータを作成
  const params = {
    Key: key,
    UpdateExpression: `remove ${name}[${index}]`,
    ReturnValues: 'ALL_NEW'
  }

  return database
    .update(params)
    .promise()
    .then(R.prop('Attributes'))
}

const addNumOfSomeAttribute = (table, key, item, num) => {
  const database = createDatabaseConnection(TABLES[table])

  const params = {
    Key: key,
    UpdateExpression: 'ADD #key :value',
    ExpressionAttributeNames: { '#key': item },
    ExpressionAttributeValues: { ':value': num },
    ReturnValues: 'ALL_NEW'
  }

  return database
    .update(params)
    .promise()
    .then(R.prop('Attributes'))
}

export default {
  fetchItems,
  fetchAllItems,
  getItem,
  createItem,
  updateItem,
  deleteItem,
  deleteAttributesFromItem,
  appendListItem,
  removeListItem,
  addNumOfSomeAttribute
}
