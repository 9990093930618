import AWS from 'aws-sdk/global'
import DynamoDB from 'aws-sdk/clients/dynamodb'
import S3 from 'aws-sdk/clients/s3'
import Promise from 'bluebird'

AWS.config.region = 'ap-northeast-1'
AWS.config.setPromisesDependency(Promise)

export const TABLES = {}
export const BUCKETS = {}

export const initializeAwsSettings = (region, identityPoolId, domain, base) => {
  AWS.config.region = region

  AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: identityPoolId
  })

  TABLES.DELIVERY = `${domain}_Delivery`
  TABLES.CONTENT = `${domain}_Content`
  TABLES.STAFF = `${domain}_Staff`
  TABLES.ORG = `${domain}_Org`
  TABLES.TEST_RESULT = `${domain}_TestResult`
  TABLES.ACCESS_LOG = `${domain}_AccessLog`

  BUCKETS.CONTENT = `content.${domain}.${base}`

  return AWS.config.credentials.getPromise().then(() => AWS.config.credentials.data.IdentityId)
}

export const createDatabaseConnection = tableName => {
  return new DynamoDB.DocumentClient({ params: { TableName: tableName } })
}

export const createStorageConnection = bucketName => {
  return new S3({ httpOptions: { timeout: 86400000 }, params: { Bucket: bucketName } })
}
