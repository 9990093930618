import React from 'react'
import PropTypes from 'prop-types'
import AppBar from '@material-ui/core/AppBar'
import ToolBar from '@material-ui/core/Toolbar'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import CloseIcon from '@material-ui/icons/Close'
import Slide from '@material-ui/core/Slide'
import LanguageItems from './LanguageItems'

const Header = ({ close }) => {
  const styles = {
    toolBar: { justifyContent: 'space-between' },
    button: { color: '#fff', textTransform: 'initial' },
    right: { width: 64 }
  }
  return (
    <AppBar position="static" color="secondary">
      <ToolBar style={styles.toolBar}>
        <Button onClick={close} style={styles.button}>
          <CloseIcon />
        </Button>

        <div>Select Language</div>

        {/* ページタイトルを真ん中に位置させるための空のコンポーネント */}
        <div style={styles.right} />
      </ToolBar>
    </AppBar>
  )
}

const Transition = props => <Slide direction="up" {...props} />

const LanguageSelectModal = ({ open, close, changeLanguage }) => {
  const onClick = lang => {
    changeLanguage(lang)
    close()
  }

  const styles = {
    toolBar: { justifyContent: 'space-between' },
    button: {
      color: '#fff',
      textTransform: 'initial'
    },
    right: { width: 64 },
    items: { marginTop: 20 }
  }

  return (
    <Dialog fullScreen open={open} onClose={close} TransitionComponent={Transition}>
      <Header close={close} />
      <div style={styles.items}>
        <LanguageItems onClick={onClick} />
      </div>
    </Dialog>
  )
}

export default LanguageSelectModal

/* PropTypes */

Header.propTypes = {
  close: PropTypes.func.isRequired
}

LanguageSelectModal.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  changeLanguage: PropTypes.func.isRequired
}
