import React from 'react'
import PropTypes from 'prop-types'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import { withTranslation } from 'react-i18next'
import * as R from 'ramda'
import DeliveryItem from './DeliveryListItem'

const { useEffect, useState } = React

const DeliveryList = ({ deliveries, contents, allListsOpened, toggleAllLists, isSentTabSelected, isSmallWidth, t }) => {
  const deliveriesAssociatedWithContents = deliveries.map(delivery => {
    const contentsAssociatedWithDelivery = delivery.contents.map(contentId => contents[contentId])
    return R.assoc('contents', contentsAssociatedWithDelivery, delivery)
  })

  // 各コンテンツリストが開いているか閉じているかを管理するためのリスト
  const [isListOpened, updateList] = useState([])
  // 配信の数と同じ長さの配列を用意し、すべてtrueにしてコンテンツリストを開く
  useEffect(() => updateList(Array(deliveries.length).fill(true)), [deliveries])

  // 各コンテンツリストの開閉を切り替え
  const toggleList = index => isOpened => updateList(R.update(index, isOpened, isListOpened))

  useEffect(
    () => {
      if (R.isEmpty(isListOpened)) return

      // すべてのコンテンツリストが開かれたら、「すべて閉じる」ボタンを「すべて開く」ボタンに変更
      if (R.all(isOpened => isOpened, isListOpened)) toggleAllLists(true)
      // すべてのコンテンツリストが閉じられたら、「すべて開く」ボタンを「すべて閉じる」ボタンに変更
      else if (R.all(isOpened => !isOpened, isListOpened)) toggleAllLists(false)
    },
    [isListOpened]
  )
  useEffect(
    () => {
      // 「すべて開く」ボタンが押されたら、コンテンリストをすべて開く
      if (allListsOpened) updateList(Array(deliveries.length).fill(true))
      // 「すべて閉じる」ボタンが押されたら、コンテンリストをすべて閉じる
      else updateList(Array(deliveries.length).fill(false))
    },
    [allListsOpened]
  )

  const styles = {
    listItem: { border: 'solid 1px #e6e6e6' }
  }

  return (
    <div>
      {deliveriesAssociatedWithContents.length ? (
        deliveriesAssociatedWithContents.map((delivery, index) => {
          // マウント直後はisListOpened[index]がundefinedになってしまうため、デフォルトでtrueを入れておく
          const opened = R.defaultTo(true, isListOpened[index])

          return (
            <DeliveryItem
              key={delivery.id}
              delivery={delivery}
              isSentTabSelected={isSentTabSelected}
              isListOpened={opened}
              toggleList={toggleList(index)}
              isSmallWidth={isSmallWidth}
            />
          )
        })
      ) : (
        <ListItem style={styles.listItem}>
          <ListItemText>{isSentTabSelected ? t('noSent') : t('noArchive')}</ListItemText>
        </ListItem>
      )}
    </div>
  )
}

export default withTranslation()(DeliveryList)

/* PropTypes */
DeliveryList.propTypes = {
  deliveries: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired
    }).isRequired
  ).isRequired,
  contents: PropTypes.object.isRequired,
  allListsOpened: PropTypes.bool.isRequired,
  toggleAllLists: PropTypes.func.isRequired,
  isSentTabSelected: PropTypes.bool.isRequired,
  isSmallWidth: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired
}
