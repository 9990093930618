import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Avatar from '@material-ui/core/Avatar'
import { withTranslation } from 'react-i18next'

const TabLabel = ({ label, numOfDeliveries }) => {
  const existsDelivery = numOfDeliveries !== 0

  const styles = {
    tabLabel: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center'
    },
    tabNumber: {
      width: 25,
      height: 25,
      fontSize: 16,
      backgroundColor: '#26a69a',
      marginLeft: 5
    }
  }

  return (
    <div style={styles.tabLabel}>
      <div>{label}</div>
      {existsDelivery ? (
        <Avatar style={styles.tabNumber} sizes="100">
          {numOfDeliveries}
        </Avatar>
      ) : null}
    </div>
  )
}

const DeliveryTab = ({ selectedTab, numOfSent, numOfEnded, changeTab, classes, t }) => {
  const tabClass = { root: classes.tabRoot, selected: classes.tabSelected }

  return (
    <Tabs value={selectedTab} onChange={changeTab} classes={{ indicator: classes.tabsIndicator }}>
      <Tab label={<TabLabel label={t('contentsDelivered')} numOfDeliveries={numOfSent} />} classes={tabClass} />
      <Tab label={<TabLabel label={t('contentsArchive')} numOfDeliveries={numOfEnded} />} classes={tabClass} />
    </Tabs>
  )
}

const styles = () => ({
  tabsIndicator: {
    top: 0
  },
  tabRoot: {
    height: 60,
    textTransform: 'initial',
    backgroundColor: '#f2f2f2',
    borderTop: 'solid 1px #e6e6e6',
    borderLeft: 'solid 1px #e6e6e6',
    '&:last-child': {
      borderRight: 'solid 1px #e6e6e6'
    },
    '&:hover': {
      color: '#40a9ff',
      opacity: 1
    }
  },
  tabSelected: {
    backgroundColor: '#fff'
  }
})

export default withTranslation()(withStyles(styles)(DeliveryTab))

/* PropTypes */
TabLabel.propTypes = {
  label: PropTypes.string.isRequired,
  numOfDeliveries: PropTypes.number.isRequired
}

DeliveryTab.propTypes = {
  selectedTab: PropTypes.number.isRequired,
  numOfSent: PropTypes.number.isRequired,
  numOfEnded: PropTypes.number.isRequired,
  changeTab: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired
}
