import React from 'react'
import PropTypes from 'prop-types'
import List from '@material-ui/core/List'
import Modal from '@material-ui/core/Modal'
import Snackbar from '@material-ui/core/Snackbar'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import * as R from 'ramda'
import Dialog from '../components/Common/Dialog'
import ContentItem from '../components/Content/ContentItem'
import ContentView from '../components/Content/ContentView'

const ContentLayout = ({
  contents,
  confirmedContents,
  selectedContent,
  status,
  openContent,
  onConfirm,
  alertDialogOpen,
  toggleAlertDialog,
  contentModalOpen,
  closeContent,
  snackbarOpen,
  toggleSnackbar,
  snackbarText,
  isSmallWidth,
  t
}) => {
  const styles = {
    list: { padding: 0, borderBottom: 'solid 1px #e6e6e6' },
    listItem: {
      borderTop: 'solid 1px #e6e6e6',
      borderLeft: 'solid 1px #e6e6e6',
      borderRight: 'solid 1px #e6e6e6'
    }
  }

  return (
    <div>
      <List style={styles.list}>
        {contents.map(content => {
          const isPending = status === 'pending'
          const isArchive = status === 'archive'
          const hasConfirmed = R.includes(content.id, confirmedContents)

          return (
            <ContentItem
              key={content.id}
              content={content}
              isPending={isPending}
              isArchive={isArchive}
              hasConfirmed={hasConfirmed}
              openContent={openContent}
              onConfirm={onConfirm}
              isSmallWidth={isSmallWidth}
            />
          )
        })}
      </List>

      {/* コンテンツ確認前に確認済みにするボタンを押したら表示するアラート */}
      <Dialog text={t('doneAlert')} open={alertDialogOpen} onClick={() => toggleAlertDialog(false)} />

      {/* コンテンツを表示 */}
      <Modal open={contentModalOpen}>
        <ContentView title={selectedContent.title} uri={selectedContent.uri} handleClose={closeContent} />
      </Modal>

      {/* 確認済みにするボタンの成否を画面下部に表示する */}
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        autoHideDuration={3000}
        open={snackbarOpen}
        onClose={() => toggleSnackbar(false)}
        message={snackbarText}
      />
    </div>
  )
}

const mapStateToProps = state => ({
  isSmallWidth: state.layout.isSmallWidth
})

export default connect(mapStateToProps)(withTranslation()(ContentLayout))

/* PropTypes */
ContentLayout.propTypes = {
  contents: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired
    }).isRequired
  ).isRequired,
  confirmedContents: PropTypes.array.isRequired,
  selectedContent: PropTypes.object.isRequired,
  status: PropTypes.string.isRequired,
  openContent: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  alertDialogOpen: PropTypes.bool.isRequired,
  toggleAlertDialog: PropTypes.func.isRequired,
  contentModalOpen: PropTypes.bool.isRequired,
  closeContent: PropTypes.func.isRequired,
  snackbarOpen: PropTypes.bool.isRequired,
  toggleSnackbar: PropTypes.func.isRequired,
  snackbarText: PropTypes.string.isRequired,
  isSmallWidth: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired
}
