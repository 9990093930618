import * as R from 'ramda'
import db from './database'

const tableName = 'STAFF'

const findStaffById = id => {
  const params = {
    KeyConditionExpression: '#i = :i',
    ExpressionAttributeNames: { '#i': 'id' },
    ExpressionAttributeValues: { ':i': id }
  }

  return db.fetchItems({ table: tableName, params }).then(
    R.compose(
      R.head,
      R.prop('items')
    )
  )
}

const findStaffByStaffId = staffId => {
  const params = {
    IndexName: 'no-index',
    KeyConditionExpression: '#n = :n',
    ExpressionAttributeNames: { '#n': 'no' },
    ExpressionAttributeValues: { ':n': staffId }
  }

  return db.fetchItems({ table: tableName, params }).then(
    R.compose(
      R.head,
      R.prop('items')
    )
  )
}

const findStaffByMailAddress = mail => {
  const params = {
    IndexName: 'mail-index',
    KeyConditionExpression: '#m = :m',
    ExpressionAttributeNames: { '#m': 'mail' },
    ExpressionAttributeValues: { ':m': mail }
  }

  return db.fetchItems({ table: tableName, params }).then(
    R.compose(
      R.head,
      R.prop('items')
    )
  )
}

const updateStaffItem = (id, item) => {
  return db.updateItem(tableName, { id }, item)
}

export default {
  findStaffById,
  findStaffByStaffId,
  findStaffByMailAddress,
  updateStaffItem
}
