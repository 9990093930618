import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import WindowLayout from './pages/WindowLayout'
import Login from './pages/Login'
import Error from './pages/Error'
import Auth from './pages/Auth'
import Delivery from './pages/Delivery'
import Qr from './pages/Qr'

const Routes = () => {
  return (
    <BrowserRouter>
      <Switch>
        <WindowLayout>
          <Switch>
            <Route exact path="/" component={Login} />
            <Route exact path="/error" component={Error} />
            <Auth>
              <Switch>
                <Route path="/list" component={Delivery} />
                <Route path="/qr" component={Qr} />
                {/* 上のURLにマッチしなかった場合は、Indexコンポーネントを表示する */}
                <Route component={Login} />
              </Switch>
            </Auth>
          </Switch>
        </WindowLayout>
      </Switch>
    </BrowserRouter>
  )
}

export default Routes
