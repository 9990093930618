import * as R from 'ramda'
import { handleActions } from 'redux-actions'

const handlers = {
  UPDATE_STAFFS_LANGUAGE: (state, action) => {
    const profile = R.assoc('lang', action.payload, state.profile)

    const fontSize = action.payload === 'ja' ? 14 : 16
    const layout = R.assoc('fontSize', fontSize, state.layout)

    return { ...state, profile, isLanguageSelected: true, layout }
  },
  SELECT_LANGUAGE: state => {
    const fontSize = state.profile.lang === 'ja' ? 14 : 16
    const layout = R.assoc('fontSize', fontSize, state.layout)
    return { ...state, isLanguageSelected: true, layout }
  },
  FETCH_STAFF: (state, action) => {
    var profile = {}
    if (action.payload) {
      profile = { ...state.profile, ...action.payload }
    }
    return { ...state, profile }
  },
  FETCH_ORG: (state, action) => {
    var org = {}
    if (action.payload) {
      org = { ...state.org, ...action.payload }
    }
    return { ...state, org }
  },
  FETCH_VERSION: (state, action) => {
    return { ...state, version: action.payload }
  },
  FETCH_DELIVERIES: (state, action) => {
    const { ids, records } = action.payload
    const categories = R.compose(
      R.uniq,
      R.reject(R.isNil),
      R.pluck('category'),
      R.values
    )(records)

    // 各々のカテゴリーに連番のidを振る（並び替えのため）
    // id = 0は「すべて」に当てられているので、1から番号を振る
    const categoriesWithId = R.times(index => ({ id: index + 1, name: categories[index] }), categories.length)
    const categoryList = [{ id: 0 }, { id: 9999 }, ...categoriesWithId]

    const delivery = { ...state.delivery, ids, records, categoryList }
    return { ...state, delivery }
  },
  FETCH_CONTENTS: (state, action) => {
    const { ids, records } = action.payload

    const mergedIds = R.concat(state.content.ids, ids)
    const mergedRecords = R.merge(state.content.records, records)
    const content = { ...state.content, ids: mergedIds, records: mergedRecords }

    return { ...state, content }
  },
  FETCH_TEST_RESULTS: (state, action) => {
    const { results } = action.payload
    const result = R.concat(state.result, results)

    return { ...state, result }
  },
  SAVE_ASYNC_STORAGE: (state, action) => {
    return { ...state, storage: action.payload }
  },
  SELECT_CONTENT: (state, action) => {
    const { id, uri, contentsError } = action.payload

    if (contentsError) {
      const error = { ...state.error, contentsError }
      return { ...state, error }
    }

    const selectedContent = R.assoc('uri', uri, state.content.records[id])
    const content = { ...state.content, selectedContent }

    const hasOpened = R.append(id, state.profile.hasOpened)
    const profile = R.assoc('hasOpened', hasOpened, state.profile)
    return { ...state, content, profile }
  },
  CLEAR_SELECTED_CONTENT: state => {
    const content = { ...state.content, selectedContent: {} }
    return { ...state, content }
  },
  CONFIRM_CONTENT: (state, action) => {
    const { res, deliveryId, contentId } = action.payload
    if (res.ok) {
      const contentIds = R.append(contentId, state.profile.contentIds)

      const { contents } = state.delivery.records[deliveryId]
      const allContentsConfirmed = R.all(R.includes(R.__, contentIds))(contents)
      // すべてのコンテンツが確認済みだったら、配信を確認済みにする
      const deliveryIds = allContentsConfirmed
        ? R.append(deliveryId, state.profile.deliveryIds)
        : state.profile.deliveryIds

      const profile = R.merge(state.profile, { contentIds, deliveryIds })
      return { ...state, profile }
    } else {
      return state
    }
  },
  CONFIRM: state => {
    return { ...state, isConfirmed: true }
  },
  AUTHENTICATE: state => {
    return { ...state, isAuthenticated: true }
  },
  CHANGE_STATUS: (state, action) => {
    const { status } = action.payload
    const app = { ...state.app, selectedStatus: status }
    return { ...state, app }
  },
  CHANGE_CATEGORY: (state, action) => {
    const { categoryId } = action.payload
    const app = { ...state.app, selectedCategoryId: categoryId }
    return { ...state, app }
  },
  CHANGE_WIDTH: (state, action) => {
    const width = action.payload.width
    const isSmallWidth = width === 'sm' || width === 'xs'
    const layout = R.assoc('isSmallWidth', isSmallWidth, state.layout)
    return { ...state, layout }
  },
  MAKE_LOGIN_ERROR: state => {
    return { ...state, error: { isLoginError: true } }
  },
  CLEAR_ERROR: state => {
    return { ...state, error: { isLoginError: false, contentsError: '' } }
  }
}

const initialState = {
  delivery: {
    ids: [],
    records: {},
    categoryList: []
  },
  content: {
    ids: [],
    records: {},
    selectedContent: {}
  },
  result: [],
  profile: {
    hasOpened: [],
    lang: ''
  },
  app: {
    selectedStatus: 'all',
    selectedCategoryId: 0
  },
  org: {},
  version: '',
  storage: null,
  isConfirmed: false, // 本人確認を行ったかどうか
  isAuthenticated: false,
  isLanguageSelected: false, // ユーザーが言語選択を行ったかどうか
  layout: {
    isSmallWidth: false,
    fontSize: 14
  },
  error: {
    isLoginError: false,
    contentsError: ''
  }
}

export default handleActions(handlers, initialState)

const filterDeliveries = (deliveries, selectedCategoryId, categoryList) => {
  const selected = R.find(R.propEq('id', selectedCategoryId), categoryList)

  return selectedCategoryId === 0
    ? deliveries
    : selectedCategoryId === 9999
      ? R.filter(R.propSatisfies(R.isNil, 'category'), deliveries)
      : R.filter(R.propEq('category', selected.name), deliveries)
}

const sortByDate = R.sort(R.descend(R.prop('at')))

export const sentDeliveries = state => {
  const sentDeliveries = R.filter(({ status }) => status === 'sent' || status === 'pending', R.values(state.delivery.records))

  const { categoryList } = state.delivery
  const { selectedCategoryId, selectedStatus } = state.app

  const deliveriesFilteredByCategory = filterDeliveries(sentDeliveries, selectedCategoryId, categoryList)

  const deliveriesAddedResult = deliveriesFilteredByCategory.map(delivery => {
    const viewed = R.includes(delivery.id, state.profile.deliveryIds)
    return { ...delivery, viewed }
  })

  const deliveriesFilteredByStatus = deliveriesAddedResult.filter(delivery => {
    if (selectedStatus === 'all') return true
    if (selectedStatus === 'notViewed') return !delivery.viewed
    if (selectedStatus === 'viewed') return delivery.viewed
  })

  return sortByDate(deliveriesFilteredByStatus)
}

export const archiveDeliveries = state => {
  const archiveDeliveries = R.filter(({ status }) => status === 'archive', R.values(state.delivery.records))

  const { categoryList } = state.delivery
  const { selectedCategoryId } = state.app

  const deliveriesFilteredByCategory = filterDeliveries(archiveDeliveries, selectedCategoryId, categoryList)

  const deliveriesAddedResult = deliveriesFilteredByCategory.map(delivery => {
    const viewed = R.includes(delivery.id, state.profile.deliveryIds)
    return { ...delivery, viewed }
  })

  return sortByDate(deliveriesAddedResult)
}
