import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import AppBar from '@material-ui/core/AppBar'
import ToolBar from '@material-ui/core/Toolbar'
import Button from '@material-ui/core/Button'
import CloseIcon from '@material-ui/icons/Close'
import { withTranslation } from 'react-i18next'

const QrHeader = ({ goBack, isSmallWidth, t }) => {
  const styles = {
    toolBar: { justifyContent: 'space-between' },
    button: {
      color: '#fff',
      textTransform: 'initial',
      paddingLeft: isSmallWidth ? 8 : 16,
      paddingRight: isSmallWidth ? 8 : 16,
      marginRight: isSmallWidth ? 8 : 0,
      minWidth: 0
    },
    right: { width: 60 }
  }

  return (
    <AppBar position="static" color="secondary">
      <ToolBar style={styles.toolBar}>
        <Button disableFocusRipple onClick={goBack} style={styles.button}>
          {isSmallWidth ? <CloseIcon /> : t('back')}
        </Button>

        <Typography variant="subheading" color="inherit">
          {t('scanQRcode')}
        </Typography>

        {/* ページタイトルを真ん中に位置させるための空のコンポーネント */}
        {isSmallWidth ? null : <div style={styles.right} />}
      </ToolBar>
    </AppBar>
  )
}

export default withTranslation()(QrHeader)

/* PropTypes */
QrHeader.propTypes = {
  goBack: PropTypes.func.isRequired,
  isSmallWidth: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired
}
