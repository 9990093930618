import delivery from './database/delivery'
import content from './database/content'
import staff from './database/staff'
import org from './database/org'
import testResult from './database/testResult'
import accessLog from './database/accessLog'

export default {
  ...delivery,
  ...content,
  ...staff,
  ...org,
  ...testResult,
  ...accessLog
}
