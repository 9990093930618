import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Drawer from '@material-ui/core/Drawer'
import CircularProgress from '@material-ui/core/CircularProgress'
import { connect } from 'react-redux'
import DeliveryHeader from '../components/Delivery/DeliveryHeader'
import DeliverySide from '../components/Delivery/DeliverySide'
import DeliveryTab from '../components/Delivery/DeliveryTab'
import DeliveryListButtons from '../components/Delivery/DeliveryListButtons'
import DeliveryList from '../components/Delivery/DeliveryList'
import UserConfirmDialog from '../components/Delivery/UserConfirmDialog'
import ErrorDialog from '../components/Common/ErrorDialog'

const DeliveryLayout = ({
  isRefresh,
  allListsOpened,
  toggleAllLists,
  drawerOpen,
  onChangeStatus,
  onChangeCategory,
  openDrawer,
  refresh,
  closeDrawer,
  staffName,
  orgName,
  sentDeliveries,
  archiveDeliveries,
  selectedStatus,
  categoryList,
  contents,
  selectedCategoryId,
  version,
  isSmallWidth
}) => {
  const [selectedTab, selectTab] = useState(0)
  const isSentTabSelected = selectedTab === 0
  const deliveries = isSentTabSelected ? sentDeliveries : archiveDeliveries

  const changeTab = (event, selectedTab) => selectTab(selectedTab)

  const headerHeight = 64
  const sideWidth = 180

  const documentHeight = document.documentElement.clientHeight
  const styles = {
    wrapper: { display: 'flex', flexDirection: 'row' },
    body: { display: 'flex', flexDirection: 'column', width: '100%' },
    side: { borderRight: 'solid 1px #e6e6e6', height: documentHeight },
    main: { padding: 16 },
    // progress: {
    //   zIndex: 10,
    //   width: '100%',
    //   height: '100%',
    // },
    spinner: {
      position: 'fixed',
      inset: 0,
      margin: 'auto',
      width: 100,
      height: 100,
    },
    header: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingRight: isSmallWidth ? 0 : 20
    }
  }

  return (
    <div style={styles.wrapper}>
      {isSmallWidth ? (
        <Drawer variant="temporary" anchor="left" open={drawerOpen} onClose={closeDrawer}>
          <DeliverySide
            staffName={staffName}
            orgName={orgName}
            version={version}
            selectedStatus={selectedStatus}
            categoryList={categoryList}
            selectedCategoryId={selectedCategoryId}
            isSentTabSelected={isSentTabSelected}
            sideWidth={sideWidth}
            headerHeight={headerHeight}
            onChangeStatus={onChangeStatus}
            onChangeCategory={onChangeCategory}
          />
        </Drawer>
      ) : (
        <div style={styles.side}>
          <DeliverySide
            staffName={staffName}
            orgName={orgName}
            version={version}
            selectedStatus={selectedStatus}
            categoryList={categoryList}
            selectedCategoryId={selectedCategoryId}
            isSentTabSelected={isSentTabSelected}
            sideWidth={sideWidth}
            headerHeight={headerHeight}
            onChangeStatus={onChangeStatus}
            onChangeCategory={onChangeCategory}
          />
        </div>
      )}

      <div style={styles.body}>
        <DeliveryHeader headerHeight={headerHeight} openDrawer={openDrawer} isSmallWidth={isSmallWidth} />

        {isRefresh
          ? <CircularProgress style={styles.spinner} />
          : <div id="contents" style={styles.main}>
            <div style={styles.header}>
              <DeliveryTab
                selectedTab={selectedTab}
                numOfSent={sentDeliveries.length}
                numOfEnded={archiveDeliveries.length}
                changeTab={changeTab}
              />
              <DeliveryListButtons
                allListsOpened={allListsOpened}
                refresh={refresh}
                toggleAllLists={toggleAllLists}
                isSmallWidth={isSmallWidth}
              />
            </div>

            <DeliveryList
              deliveries={deliveries}
              contents={contents}
              allListsOpened={allListsOpened}
              toggleAllLists={toggleAllLists}
              isSentTabSelected={isSentTabSelected}
              isSmallWidth={isSmallWidth}
            />

            <UserConfirmDialog />
          </div>
        }
      </div>

      <ErrorDialog />
    </div>
  )
}

const mapStateToProps = state => ({
  isSmallWidth: state.layout.isSmallWidth
})

export default connect(mapStateToProps)(DeliveryLayout)

/* PropTypes */
DeliveryLayout.propTypes = {
  isRefresh: PropTypes.bool.isRequired,
  allListsOpened: PropTypes.bool.isRequired,
  toggleAllLists: PropTypes.func.isRequired,
  drawerOpen: PropTypes.bool.isRequired,
  onChangeStatus: PropTypes.func.isRequired,
  onChangeCategory: PropTypes.func.isRequired,
  openDrawer: PropTypes.func.isRequired,
  refresh: PropTypes.func.isRequired,
  closeDrawer: PropTypes.func.isRequired,
  sentDeliveries: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired
    }).isRequired
  ).isRequired,
  archiveDeliveries: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired
    }).isRequired
  ).isRequired,
  staffName: PropTypes.string.isRequired,
  orgName: PropTypes.string.isRequired,
  selectedStatus: PropTypes.string.isRequired,
  categoryList: PropTypes.array.isRequired,
  contents: PropTypes.object.isRequired,
  selectedCategoryId: PropTypes.number.isRequired,
  version: PropTypes.string.isRequired,
  isSmallWidth: PropTypes.bool.isRequired
}
