import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import * as R from 'ramda'
import Typography from '@material-ui/core/Typography'
import MenuList from '@material-ui/core/MenuList'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemText from '@material-ui/core/ListItemText'
import { withTranslation } from 'react-i18next'
import i18n from '../../i18n'

const UserInfo = ({ name, orgName, headerHeight }) => {
  const styles = {
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: headerHeight,
      borderBottom: 'solid 1px #e6e6e6'
    },
    orgName: { color: '#8c8c8c' }
  }

  return (
    <div style={styles.wrapper}>
      <Typography variant="subheading">{name}</Typography>
      <Typography style={styles.orgName}>{orgName}</Typography>
    </div>
  )
}

const StatusList = ({ selectedStatus, onChangeStatus, t }) => {
  const styles = {
    wrapper: { marginBottom: 20 },
    listTitle: { textAlign: 'center', marginTop: 10, fontWeight: 'bold' },
    list: { flexShrink: 0, marginTop: 10 },
    listItem: { padding: 0, textAlign: 'center' }
  }

  return (
    <div style={styles.wrapper}>
      <Typography style={styles.listTitle}>{t('statusList')}</Typography>
      <MenuList style={styles.list}>
        <MenuItem selected={selectedStatus === 'all'} onClick={() => onChangeStatus('all')}>
          <ListItemText style={styles.listItem} primary={t('all')} />
        </MenuItem>
        <MenuItem selected={selectedStatus === 'notViewed'} onClick={() => onChangeStatus('notViewed')}>
          <ListItemText style={styles.listItem} primary={t('notViewed')} />
        </MenuItem>
        <MenuItem selected={selectedStatus === 'viewed'} onClick={() => onChangeStatus('viewed')}>
          <ListItemText style={styles.listItem} primary={t('viewed')} />
        </MenuItem>
      </MenuList>
    </div>
  )
}

const CategoryList = ({ categoryList, selectedCategoryId, onChangeCategory, t }) => {
  const styles = {
    listTitle: { textAlign: 'center', marginTop: 10, fontWeight: 'bold' },
    list: { flexShrink: 0, marginTop: 10 },
    listItem: { padding: 0, textAlign: 'center' }
  }

  const getCategoryName = categoryId => {
    if (categoryId === 0) return t('all')
    if (categoryId === 9999) return t('uncategorized')

    return R.prop('name', R.find(R.propEq('id', categoryId), categoryList))
  }

  const sortedCategoryList = R.sort(R.ascend(R.prop('id')), categoryList)

  return (
    <div>
      <Typography style={styles.listTitle}>{t('categoryList')}</Typography>
      <MenuList style={styles.list}>
        {sortedCategoryList.map(category => {
          const categoryName = getCategoryName(category.id)

          return (
            <MenuItem
              key={category.id}
              selected={category.id === selectedCategoryId}
              onClick={() => onChangeCategory(category.id)}
            >
              <ListItemText style={styles.listItem} primary={categoryName} />
            </MenuItem>
          )
        })}
      </MenuList>
    </div>
  )
}

const ContentSide = ({
  staffName,
  orgName,
  version,
  selectedStatus,
  categoryList,
  selectedCategoryId,
  isSentTabSelected,
  sideWidth,
  headerHeight,
  onChangeStatus,
  onChangeCategory,
  history,
  location,
  t
}) => {
  const displayQrCode = () => history.push('/qr' + location.search)

  const nameWithHonorific = i18n.language === 'ja' ? `${staffName} さん` : staffName

  const styles = {
    wrapper: {
      width: sideWidth,
      height: '100%',
      display: 'flex',
      flexDirection: 'column'
    },
    sideBarBottom: {
      marginTop: 10
    },
    qr: {
      marginTop: 20,
      textAlign: 'center',
      whiteSpace: 'pre-wrap',
      cursor: 'pointer'
    },
    version: {
      marginTop: 20,
      marginBottom: 20,
      textAlign: 'center'
    }
  }

  return (
    <div style={styles.wrapper}>
      <UserInfo name={nameWithHonorific} orgName={orgName} headerHeight={headerHeight} />

      {isSentTabSelected ? <StatusList selectedStatus={selectedStatus} onChangeStatus={onChangeStatus} t={t} /> : null}

      <CategoryList
        categoryList={categoryList}
        selectedCategoryId={selectedCategoryId}
        onChangeCategory={onChangeCategory}
        t={t}
      />

      <div style={styles.sideBarBottom}>
        <Typography style={styles.qr} color="primary" onClick={displayQrCode}>
          {t('useMobileApp')}
        </Typography>
        <Typography style={styles.version}>{`${t('version')} ${version}`}</Typography>
      </div>
    </div>
  )
}

export default withRouter(withTranslation()(ContentSide))

/* PropTypes */
UserInfo.propTypes = {
  name: PropTypes.string.isRequired,
  orgName: PropTypes.string.isRequired,
  headerHeight: PropTypes.number.isRequired
}

StatusList.propTypes = {
  selectedStatus: PropTypes.string.isRequired,
  onChangeStatus: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
}

CategoryList.propTypes = {
  categoryList: PropTypes.array.isRequired,
  selectedCategoryId: PropTypes.number.isRequired,
  onChangeCategory: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
}

ContentSide.propTypes = {
  staffName: PropTypes.string.isRequired,
  orgName: PropTypes.string.isRequired,
  version: PropTypes.string.isRequired,
  selectedStatus: PropTypes.string.isRequired,
  categoryList: PropTypes.array.isRequired,
  selectedCategoryId: PropTypes.number.isRequired,
  isSentTabSelected: PropTypes.bool.isRequired,
  sideWidth: PropTypes.number.isRequired,
  headerHeight: PropTypes.number.isRequired,
  onChangeStatus: PropTypes.func.isRequired,
  onChangeCategory: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired
}
