import * as R from 'ramda'
import Promise from 'bluebird'
import { createAction } from 'redux-actions'
import { normalize, schema } from 'normalizr'
import * as api from '../middleware'
import { CONTENT_VIEW_LIMIT } from '../../constant'
import i18n from '../../i18n'

const deliverySchema = new schema.Entity('delivery')
const deliveryListSchema = new schema.Array(deliverySchema)

const contentSchema = new schema.Entity('content')
const contentListSchema = new schema.Array(contentSchema)

const fetchOrgAction = createAction('FETCH_ORG')
export const fetchOrg = () => (dispatch, getState) => {
  const {
    profile: { orgId }
  } = getState()

  return api
    .fetchOrg(orgId)
    .then(item => dispatch(fetchOrgAction(item)))
    .catch(err => dispatch(fetchOrgAction(err)))
}

const fetchVersionAction = createAction('FETCH_VERSION')
export const fetchVersion = () => (dispatch, getState) => {
  const {
    storage: { domain }
  } = getState()

  return api
    .fetchVersion(domain)
    .then(item => dispatch(fetchVersionAction(item)))
    .catch(err => dispatch(fetchVersionAction(err)))
}

const fetchStaffAction = createAction('FETCH_STAFF')
export const fetchStaff = () => (dispatch, getState) => {
  const {
    storage: { id }
  } = getState()

  return api
    .fetchStaff(id)
    .then(item => R.prop('payload', dispatch(fetchStaffAction(item))))
    .catch(err => dispatch(fetchStaffAction(err)))
}

// 言語は切り替え、DBを更新する
const updateStaffsLanguageAction = createAction('UPDATE_STAFFS_LANGUAGE')
export const updateStaffsLanguage = ({ lang }) => (dispatch, getState) => {
  const {
    storage: { id }
  } = getState()

  i18n.changeLanguage(lang)

  return api
    .updateStaffsLanguage(id, lang)
    .then(() => dispatch(updateStaffsLanguageAction(lang)))
    .catch(err => dispatch(updateStaffsLanguageAction(err)))
}

// 言語は切り替えるが、DBの更新は行わない
const selectLanguageAction = createAction('SELECT_LANGUAGE')
export const selectLanguage = ({ lang }) => dispatch => {
  i18n.changeLanguage(lang)
  return dispatch(selectLanguageAction())
}

const fetchDeliveriesAction = createAction('FETCH_DELIVERIES', deliveries => {
  const { entities, result } = normalize(deliveries, deliveryListSchema)
  // defaultToはitemsが[]だったとき対策
  const records = R.defaultTo({})(entities.delivery)
  return { ids: result, records }
})
export const fetchDeliveries = () => (dispatch, getState) => {
  const {
    org: { id: orgId }
  } = getState()

  return api
    .fetchDeliveriesByOrgId(orgId)
    .then(items => dispatch(fetchDeliveriesAction(items)))
    .catch(err => dispatch(fetchDeliveriesAction(err)))
}

const fetchContentsAction = createAction('FETCH_CONTENTS', contents => {
  const { entities, result } = normalize(contents, contentListSchema)
  // defaultToはitemsが[]だったとき対策
  const records = R.defaultTo({})(entities.content)
  return { ids: result, records }
})
export const fetchContents = ({ ids }) => dispatch => {
  return Promise.map(ids, id => api.fetchContentsById(id))
    .then(items => dispatch(fetchContentsAction(items)))
    .catch(err => dispatch(fetchContentsAction(err)))
}

const fetchTestResultsAction = createAction('FETCH_TEST_RESULTS', results => ({ results }))
export const fetchTestResults = () => (dispatch, getState) => {
  const {
    content: { records },
    storage: { id: staffId }
  } = getState()

  const testContents = R.filter(R.either(R.propEq('purpose', 'exam'), R.propEq('purpose', 'exercise')), records)
  const contentIds = R.values(R.pluck('id', testContents))

  return api
    .fetchTestResults(contentIds, staffId)
    .then(results => dispatch(fetchTestResultsAction(results)))
    .catch(err => dispatch(fetchTestResultsAction(err)))
}

const saveAsyncStorageAction = createAction('SAVE_ASYNC_STORAGE')
export const saveAsyncStorage = obj => saveAsyncStorageAction(obj)

const selectContentAction = createAction('SELECT_CONTENT')
export const selectContent = ({ contentId, purpose }) => async (dispatch, getState) => {
  try {
    const {
      storage: { bucket, id: staffId },
      org: { id: orgId },
      content: { records }
    } = getState()

    // STORMの場合のみ、閲覧回数制限にかかっていないか確認
    if (purpose === 'storm') {
      const numOfViewed = await api.getContentViewCount(contentId)
      if (numOfViewed > CONTENT_VIEW_LIMIT) {
        return dispatch(selectContentAction({ contentsError: 'maximumLimit' }))
      }
    }

    // 閲覧回数のカウンターを追加
    await api.addContentViewCount(contentId)
    // アクセスログを記録
    await api.addOpenedContent(staffId, orgId, contentId)

    const uri =
      purpose === 'url' ? records[contentId].url : await api.getContentUri(contentId, purpose, bucket, staffId)

    return dispatch(selectContentAction({ id: contentId, uri }))
  } catch (err) {
    dispatch(selectContentAction(err))
  }
}

const clearSelectedContentAction = createAction('CLEAR_SELECTED_CONTENT')
export const clearSelectedContent = () => dispatch => {
  return dispatch(clearSelectedContentAction())
}

const addOpenedContentAction = createAction('ADD_OPENED_CONTENT')
export const addOpenedContent = ({ contentId }) => (dispatch, getState) => {
  const {
    org: { id: orgId },
    storage: { id: staffId }
  } = getState()

  return api
    .addOpenedContent(staffId, orgId, contentId)
    .then(() => dispatch(addOpenedContentAction({ contentId })))
    .catch(err => dispatch(addOpenedContentAction(err)))
}

const confirmContentAction = createAction('CONFIRM_CONTENT')
export const confirmContent = ({ deliveryId, contentId }) => async (dispatch, getState) => {
  try {
    const {
      org: { path },
      storage: { domain, apiEndpoint, id }
    } = getState()

    const res = await api.confirmContent(domain, apiEndpoint, id, deliveryId, contentId, path)
    await dispatch(confirmContentAction({ res, deliveryId, contentId }))
  } catch (err) {
    dispatch(confirmContentAction(err))
  }
}

const confirmAction = createAction('CONFIRM')
export const confirm = () => confirmAction()

const authenticateAction = createAction('AUTHENTICATE')
export const authenticate = () => authenticateAction()

const changeStatusAction = createAction('CHANGE_STATUS', status => ({ status }))
export const changeStatus = ({ status }) => changeStatusAction(status)

const changeCategoryAction = createAction('CHANGE_CATEGORY', categoryId => ({ categoryId }))
export const changeCategory = ({ categoryId }) => changeCategoryAction(categoryId)

const changeWidthAction = createAction('CHANGE_WIDTH', width => ({ width }))
export const changeWidth = ({ width }) => changeWidthAction(width)

const makeLoginErrorAction = createAction('MAKE_LOGIN_ERROR')
export const makeLoginError = () => makeLoginErrorAction()

const clearErrorAction = createAction('CLEAR_ERROR')
export const clearError = () => clearErrorAction()
