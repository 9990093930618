import React from 'react'
import PropTypes from 'prop-types'
import * as R from 'ramda'
import { withRouter } from 'react-router-dom'
import Typography from '@material-ui/core/Typography'
import AppBar from '@material-ui/core/AppBar'
import ToolBar from '@material-ui/core/Toolbar'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import LanguageIcon from '@material-ui/icons/Language'
import LogoutIcon from '@material-ui/icons/ExitToApp'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { updateStaffsLanguage } from '../../api/actions'
import LanguageSelectPopover from '../LanguageSelect/LanguageSelectPopover'
import LanguageSelectModal from '../LanguageSelect/LanguageSelectModal'
import i18n, { langs } from '../../i18n'

const { useState } = React

const NavigationBar = ({ isSmallWidth, openDrawer, t }) => {
  return (
    <ToolBar>
      {isSmallWidth ? (
        <IconButton color="inherit" aria-label="open drawer" onClick={openDrawer}>
          <MenuIcon />
        </IconButton>
      ) : null}

      <Typography variant="subheading" color="inherit">
        {t('contents')}
      </Typography>
    </ToolBar>
  )
}

const LanguageSelectButton = ({ iconOnly, onClick }) => {
  const selectedLanguage = R.compose(
    R.prop('label'),
    R.find(R.propEq('value', i18n.language))
  )(langs)

  const styles = {
    button: {
      color: '#fff',
      textTransform: 'initial',
      padding: iconOnly ? 0 : null,
      marginRight: iconOnly ? 8 : 0,
      minWidth: 0
    },
    buttonText: { marginLeft: 10 }
  }

  return (
    <Button onClick={onClick} style={styles.button}>
      <LanguageIcon />
      {!iconOnly ? <span style={styles.buttonText}>Language: {selectedLanguage}</span> : null}
    </Button>
  )
}

const LogoutButton = ({ iconOnly, logout, t }) => {
  const styles = {
    button: {
      color: '#fff',
      textTransform: 'initial',
      padding: iconOnly ? 0 : null,
      marginRight: iconOnly ? 8 : 0,
      minWidth: 0
    },
    buttonText: { marginLeft: 10 }
  }

  return (
    <Button onClick={logout} style={styles.button}>
      <LogoutIcon />
      {!iconOnly ? <span style={styles.buttonText}>{t('exit')}</span> : null}
    </Button>
  )
}

const DeliveryHeader = ({ headerHeight, openDrawer, isSmallWidth, updateStaffsLanguage, t }) => {
  const [popoverOpen, togglePopOver] = useState(false)
  const [modalOpen, toggleModal] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)

  const onClick = event => {
    setAnchorEl(event.currentTarget)
    if (isSmallWidth) toggleModal(true)
    else togglePopOver(true)
  }

  const logout = () => {
    // sotreをクリアするために、react-routerではなく、window.locationで遷移
    window.location.href = '/'
  }

  const styles = {
    header: {
      display: 'flex',
      flexDirection: 'row'
    },
    appBar: {
      height: headerHeight,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    buttons: {
      display: 'flex',
      flexDirection: 'row'
    }
  }

  return (
    <div style={styles.header}>
      <AppBar position="static" color="secondary" style={styles.appBar}>
        <NavigationBar isSmallWidth={isSmallWidth} openDrawer={openDrawer} t={t} />

        <div style={styles.buttons}>
          <LanguageSelectButton iconOnly={isSmallWidth} onClick={onClick} />
          <LogoutButton iconOnly={isSmallWidth} logout={logout} t={t} />
        </div>

        <LanguageSelectPopover
          open={popoverOpen}
          anchorEl={anchorEl}
          close={() => togglePopOver(false)}
          changeLanguage={lang => updateStaffsLanguage({ lang })}
        />

        <LanguageSelectModal
          open={modalOpen}
          close={() => toggleModal(false)}
          changeLanguage={lang => updateStaffsLanguage({ lang })}
        />
      </AppBar>
    </div>
  )
}

export default withRouter(
  connect(
    null,
    { updateStaffsLanguage }
  )(withTranslation()(DeliveryHeader))
)

/* PropTypes */
NavigationBar.propTypes = {
  isSmallWidth: PropTypes.bool.isRequired,
  openDrawer: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
}

LanguageSelectButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  iconOnly: PropTypes.bool.isRequired
}

LogoutButton.propTypes = {
  logout: PropTypes.func.isRequired,
  iconOnly: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired
}

DeliveryHeader.propTypes = {
  headerHeight: PropTypes.number.isRequired,
  openDrawer: PropTypes.func.isRequired,
  isSmallWidth: PropTypes.bool.isRequired,
  updateStaffsLanguage: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
}
