import React from 'react'
import PropTypes from 'prop-types'
import CircularProgress from '@material-ui/core/CircularProgress'
import { connect } from 'react-redux'
import LoginForm from '../components/Login/LoginForm'
import logo from '../images/sitetitle.png'

const LoginLayout = ({ onLogin, isMounting, isLoggingIn, isLoginError, isSmallWidth }) => {
  const styles = {
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      justifyContent: 'center'
    },
    mounting: { alignSelf: 'center' },
    loggingIn: { position: 'absolute' },
    form: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: 20,
      backgroundColor: '#f2f2f2'
    },
    image: { marginBottom: 20 }
  }

  return (
    <div style={styles.wrapper}>
      {isMounting ? (
        <CircularProgress size={50} style={styles.mounting} />
      ) : (
        <div style={styles.form}>
          {isLoggingIn ? <CircularProgress size={50} style={styles.loggingIn} /> : null}
          <img src={logo} alt="ロゴ" style={styles.image} />
          <LoginForm onLogin={onLogin} isLoginError={isLoginError} isSmallWidth={isSmallWidth} />
        </div>
      )}
    </div>
  )
}

const mapStateToProps = state => ({
  isSmallWidth: state.layout.isSmallWidth
})

export default connect(mapStateToProps)(LoginLayout)

/* PropTypes */
LoginLayout.propTypes = {
  onLogin: PropTypes.func.isRequired,
  isMounting: PropTypes.bool.isRequired,
  isLoggingIn: PropTypes.bool.isRequired,
  isLoginError: PropTypes.bool.isRequired,
  isSmallWidth: PropTypes.bool.isRequired
}
