import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import * as R from 'ramda'
import Promise from 'bluebird'
import { selectContent, clearSelectedContent, fetchStaff, fetchTestResults, confirmContent } from '../api/actions'
import ContentLayout from '../layouts/Content'

const { useState, useEffect, useRef } = React

const Content = ({
  deliveryId,
  status,
  contents,
  confirmedContents,
  selectedContent,
  openedContents,
  contentsError,
  selectContent,
  clearSelectedContent,
  fetchStaff,
  fetchTestResults,
  confirmContent,
  t
}) => {
  // 確認済みボタンを押したコンテンツのID
  const [confirmingContentId, changeConfirmingContentId] = useState(0)
  const [externalContentWindow, changeExternalContentWindow] = useState(null)
  const [alertDialogOpen, toggleAlertDialog] = useState(false)
  const [contentModalOpen, toggleContentModal] = useState(false)
  const [snackbarOpen, toggleSnackbar] = useState(false)
  const [snackbarText, setSnackbarText] = useState('')

  const isConfirming = useRef(false) // 確認済みにする処理が進行中かどうかを判定

  // コンテンツがクリックされたら、コンテンツの情報を取得して、選択中コンテンツを更新する
  const openContent = async contentId => {
    const { purpose } = R.find(R.propEq('id', contentId), contents)

    // クリック直後に外部ウィンドウを開かないとブロックされるので、ここで空のウィンドウを開く
    if (R.includes(purpose, ['manual', 'storm', 'url', 'exercise', 'exam', 'questionnaire'])) {
      const externalWindow = window.open('', 'newwindow')
      changeExternalContentWindow(externalWindow)
    }

    await selectContent({ contentId, purpose })
    if (contentsError) return
  }

  const closeContent = () => {
    toggleContentModal(false)

    // 選択中コンテンツをクリア。クリアしないと、未確認／確認済みタブを切り替えるたびにコンテンツが開いてしまう
    return clearSelectedContent()
  }

  // 選択中コンテンツが更新されたら、コンテンツを開く
  useEffect(
    () => {
      const openContentWindow = async () => {
        const { purpose } = selectedContent

        // THiNQコンテンツは外部ウィンドウで開いた上で、閉じたかどうかを監視する
        const isThinq = purpose => purpose === 'exercise' || purpose === 'exam' || purpose === 'questionnaire'
        if (isThinq(purpose)) {
          window.open(selectedContent.uri, 'newwindow')
          // コンテンツが開いた時点で選択中コンテンツをクリア
          await clearSelectedContent()
          // ウィンドウが閉じているか1秒ごとに確認
          while (!externalContentWindow.closed) await Promise.delay(1000)

          // 受験済みだったら、確認済みコンテンツに移すために、スタッフ情報をフェッチ
          await fetchStaff()
          // ここで受験結果をフェッチしておかないと、確認済みタブに移った時に結果がないというエラーが出る
          await fetchTestResults()
        }
        // 手順書、STORM、URLコンテンツは外部ウィンドウで開く
        else if (purpose === 'manual' || purpose === 'storm' || purpose === 'url') {
          await clearSelectedContent()
          window.open(selectedContent.uri, 'newwindow')

          // ウィンドウが閉じているか1秒ごとに確認
          while (!externalContentWindow.closed) await Promise.delay(1000)
        }
        // それ以外はコンテンツモーダルで開く
        else {
          toggleContentModal(true)
        }
      }

      // 選択中コンテンツが空の時（マウント時など）は、何もしないでリターン
      if (R.isEmpty(selectedContent)) return

      // 選択中コンテンツが、別の配信が持つコンテンツだったら、何もしないでリターン
      // こうしないと、配信の数だけコンテンツを開く処理が走る
      if (selectedContent.deliveryId !== deliveryId) return

      openContentWindow()
    },
    [selectedContent]
  )

  // 確認済みにする処理
  const onConfirm = async contentId => {
    const notViewed = !R.contains(contentId, openedContents)
    if (notViewed) return toggleAlertDialog(true)

    // 確認済みにするボタンが連打された時、通信が複数回走らないようにするための処理
    if (isConfirming.current) return
    isConfirming.current = true

    changeConfirmingContentId(contentId)

    await confirmContent({ deliveryId, contentId })

    isConfirming.current = false
  }

  // 結果送信終了後、下部のスナックバーを開く処理
  useEffect(
    () => {
      console.log(confirmedContents, confirmingContentId)
      // 確認済みにしようとしているコンテンツがない時（マウント時）は、早期リターン
      if (!confirmingContentId) return

      // 結果送信が成功した時
      if (R.includes(confirmingContentId, confirmedContents)) {
        setSnackbarText(t('marked'))
        toggleSnackbar(true)
        // confirmingContentIdをリセット
        // リセットしないと、THiNQのタブを閉じると毎回トーストが表示される
        changeConfirmingContentId(0)
      }
      // 結果送信に失敗した時
      else {
        setSnackbarText(t('cannotMark'))
        toggleSnackbar(true)
        // confirmingContentIdをリセット
        // リセットしないと、THiNQのタブを閉じると毎回トーストが表示される
        changeConfirmingContentId(0)
      }
    },
    [confirmedContents]
  )

  return (
    <ContentLayout
      contents={contents}
      confirmedContents={confirmedContents}
      selectedContent={selectedContent}
      status={status}
      openContent={openContent}
      onConfirm={onConfirm}
      alertDialogOpen={alertDialogOpen}
      toggleAlertDialog={toggleAlertDialog}
      contentModalOpen={contentModalOpen}
      closeContent={closeContent}
      snackbarOpen={snackbarOpen}
      toggleSnackbar={toggleSnackbar}
      snackbarText={snackbarText}
    />
  )
}

const mapStateToProps = state => ({
  confirmedContents: state.profile.contentIds,
  selectedContent: state.content.selectedContent,
  openedContents: state.profile.hasOpened,
  contentsError: state.error.contentsError
})

export default connect(
  mapStateToProps,
  { selectContent, clearSelectedContent, fetchStaff, fetchTestResults, confirmContent }
)(withTranslation()(Content))

/* PropTypes */
Content.propTypes = {
  deliveryId: PropTypes.number.isRequired,
  status: PropTypes.string.isRequired,
  contents: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired
    }).isRequired
  ).isRequired,
  confirmedContents: PropTypes.array.isRequired,
  selectedContent: PropTypes.object.isRequired,
  openedContents: PropTypes.array.isRequired,
  contentsError: PropTypes.string.isRequired,
  selectContent: PropTypes.func.isRequired,
  clearSelectedContent: PropTypes.func.isRequired,
  fetchStaff: PropTypes.func.isRequired,
  fetchTestResults: PropTypes.func.isRequired,
  confirmContent: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
}
