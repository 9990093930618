import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import * as R from 'ramda'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import Routes from './Router'
import { COLOR } from './constant'

const { useState, useEffect } = React

const baseTheme = {
  palette: {
    primary: {
      main: COLOR.PRIMARY
    },
    secondary: {
      main: COLOR.SECONDARY
    },
    teritary: {
      main: COLOR.TERITARY
    },
    negative: {
      main: COLOR.NEGATIVE
    }
  },
  typography: {
    fontSize: 14
  }
}

const ThemeProvider = ({ fontSize }) => {
  const initialTheme = createMuiTheme(baseTheme)
  const [theme, createTheme] = useState(initialTheme)

  useEffect(
    () => {
      const updatedTheme = R.assocPath(['typography', 'fontSize'], fontSize, baseTheme)
      const theme = createMuiTheme(updatedTheme)
      createTheme(theme)
    },
    [fontSize]
  )

  return (
    <MuiThemeProvider theme={theme}>
      <Routes />
    </MuiThemeProvider>
  )
}

const mapStateToProps = state => ({
  fontSize: state.layout.fontSize
})

export default connect(mapStateToProps)(ThemeProvider)

/* PropTypes */
ThemeProvider.propTypes = {
  fontSize: PropTypes.number.isRequired
}
