import React from 'react'
import PropTypes from 'prop-types'
import * as R from 'ramda'
import FormControl from '@material-ui/core/FormControl'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import Button from '@material-ui/core/Button'
import LanguageIcon from '@material-ui/icons/Language'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { selectLanguage } from '../../api/actions'
import { COLOR } from '../../constant'
import LanguageSelectPopover from '../LanguageSelect/LanguageSelectPopover'
import LanguageSelectModal from '../LanguageSelect/LanguageSelectModal'
import i18n, { langs } from '../../i18n'

const { useState } = React

const LoginInput = ({ staffInfo, onChange, onLogin, classes, t }) => {
  const onKeyDown = e => {
    if (e.keyCode === 13 && !R.isEmpty(staffInfo)) onLogin(staffInfo)
  }

  const styles = {
    root: {
      marginBottom: 20,
      width: '90vw',
      maxWidth: 400
    }
  }

  return (
    <div>
      <InputLabel htmlFor="login">{t('loginFormLabel')}</InputLabel>
      <Input
        id="login"
        style={styles.root}
        classes={{ underline: classes.underline }}
        onChange={e => onChange(e.target.value)}
        onKeyDown={onKeyDown}
      />
    </div>
  )
}

const LoginButton = ({ disabled, onLogin, t }) => {
  const styles = { button: { textTransform: 'initial' } }
  return (
    <Button
      disableRipple
      disabled={disabled}
      variant="contained"
      color="primary"
      onClick={onLogin}
      style={styles.button}
    >
      {t('goToMyPage')}
    </Button>
  )
}

const ErrorMessage = ({ t }) => {
  const styles = { color: 'red', fontWeight: 'bold' }

  return <p style={styles}>{t('loginError')}</p>
}

const LanguageSelectButton = ({ onClick }) => {
  const styles = {
    languageSelectButton: { color: COLOR.PRIMARY, textTransform: 'initial', marginTop: 10 }
  }

  const selectedLanguage = R.compose(
    R.prop('label'),
    R.find(R.propEq('value', i18n.language))
  )(langs)

  return (
    <Button onClick={onClick} style={styles.languageSelectButton}>
      <LanguageIcon />
      <span style={styles.buttonText}>Language: {selectedLanguage}</span>
    </Button>
  )
}

const LoginForm = ({ onLogin, isLoginError, classes, isSmallWidth, selectLanguage, t }) => {
  const [staffInfo, setStaffInfo] = useState('')
  const [popoverOpen, togglePopOver] = useState(false)
  const [modalOpen, toggleModal] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)

  const onClick = event => {
    setAnchorEl(event.currentTarget)
    if (isSmallWidth) toggleModal(true)
    else togglePopOver(true)
  }

  const styles = {
    formControl: {
      display: 'flex',
      alignItems: 'center'
    }
  }

  return (
    <FormControl style={styles.formControl}>
      <LoginInput staffInfo={staffInfo} onChange={setStaffInfo} onLogin={onLogin} classes={classes} t={t} />
      <LoginButton disabled={R.isEmpty(staffInfo)} onLogin={() => onLogin(staffInfo)} t={t} />
      {isLoginError ? <ErrorMessage t={t} /> : null}

      <LanguageSelectButton onClick={onClick} />

      <LanguageSelectPopover
        open={popoverOpen}
        anchorEl={anchorEl}
        close={() => togglePopOver(false)}
        changeLanguage={lang => selectLanguage({ lang })}
      />

      <LanguageSelectModal
        open={modalOpen}
        close={() => toggleModal(false)}
        changeLanguage={lang => selectLanguage({ lang })}
      />
    </FormControl>
  )
}

const styles = theme => ({
  underline: {
    '&:after': {
      borderBottomColor: theme.palette.primary.main
    }
  }
})

export default connect(
  null,
  { selectLanguage }
)(withStyles(styles, { withTheme: true })(withTranslation()(LoginForm)))

/* PropTypes */
LoginInput.propTypes = {
  staffInfo: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onLogin: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired
}

LoginButton.propTypes = {
  disabled: PropTypes.bool.isRequired,
  onLogin: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
}

ErrorMessage.propTypes = {
  t: PropTypes.func.isRequired
}

LanguageSelectButton.propTypes = {
  onClick: PropTypes.func.isRequired
}

LoginForm.propTypes = {
  onLogin: PropTypes.func.isRequired,
  isLoginError: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  isSmallWidth: PropTypes.bool.isRequired,
  selectLanguage: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
}
