import React from 'react'
import PropTypes from 'prop-types'
import Popover from '@material-ui/core/Popover'
import LanguageItems from './LanguageItems'

const LanguageSelectPopover = ({ open, anchorEl, close, changeLanguage }) => {
  const onClick = lang => {
    changeLanguage(lang)
    close()
  }

  const styles = {
    items: { width: 150 }
  }

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <div style={styles.items}>
        <LanguageItems onClick={onClick} />
      </div>
    </Popover>
  )
}

export default LanguageSelectPopover

/* PropTypes */
LanguageSelectPopover.propTypes = {
  open: PropTypes.bool.isRequired,
  anchorEl: PropTypes.object,
  close: PropTypes.func.isRequired,
  changeLanguage: PropTypes.func.isRequired
}
