import React from 'react'
import PropTypes from 'prop-types'
import { Redirect, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import '../i18n'

const Error = ({ isLoginError, location, t }) => {
  return isLoginError ? <div>{t('invalidUrl')}</div> : <Redirect to={'/' + location.search} />
}

const mapStateToProps = state => {
  return {
    isLoginError: state.error.isLoginError
  }
}

export default withRouter(connect(mapStateToProps)(withTranslation()(Error)))

/* PropTypes */
Error.propTypes = {
  isLoginError: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired
}
