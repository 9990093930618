import React from 'react'
import PropTypes from 'prop-types'
import * as R from 'ramda'
import manualIcon from '../../images/manual.png'
import pdfIcon from '../../images/pdf.png'
import stormIcon from '../../images/storm.png'
import urlIcon from '../../images/url.png'
import textIcon from '../../images/text.png'
import examIcon from '../../images/exam.png'
import exerciseIcon from '../../images/exercise.png'
import questionnaireIcon from '../../images/questionnaire.png'

const iconPath = purpose => {
  return R.cond([
    [R.equals('manual'), R.always(manualIcon)],
    [R.equals('pdf'), R.always(pdfIcon)],
    [R.equals('storm'), R.always(stormIcon)],
    [R.equals('url'), R.always(urlIcon)],
    [R.equals('text'), R.always(textIcon)],
    [R.equals('exam'), R.always(examIcon)],
    [R.equals('exercise'), R.always(exerciseIcon)],
    [R.equals('questionnaire'), R.always(questionnaireIcon)],
    [R.T, R.always(null)]
  ])(purpose)
}

const Icon = ({ purpose }) => {
  const styles = {
    wrap: {
      width: '40px',
      height: '40px',
      flexShrink: 0
    },
    img: {
      maxWidth: '100%',
      maxHeight: '100%'
    }
  }

  return (
    <div style={styles.wrap}>
      <img style={styles.img} src={iconPath(purpose)} />
    </div>
  )
}

export default Icon

/* PropTypes */
Icon.propTypes = {
  purpose: PropTypes.string
}
