import * as R from 'ramda'
import db from './database'

const tableName = 'ORG'

const findOrgById = id => {
  const params = {
    KeyConditionExpression: '#id = :id',
    ExpressionAttributeNames: { '#id': 'id' },
    ExpressionAttributeValues: { ':id': id }
  }

  return db.fetchItems({ table: tableName, params }).then(
    R.compose(
      R.head,
      R.prop('items')
    )
  )
}

export default {
  findOrgById
}
