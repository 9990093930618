import * as R from 'ramda'
import db from './database'

const tableName = 'ACCESS_LOG'

const createAccessLog = item => {
  const defaultItem = {
    _: '_',
    at: new Date().toISOString()
  }

  const mergedItem = R.merge(defaultItem, item)

  return db.createItem(tableName, { Item: mergedItem })
}

export default {
  createAccessLog
}
