import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import RefreshIcon from '@material-ui/icons/Refresh'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import { withTranslation } from 'react-i18next'

const ToggleButton = ({ allListsOpened, refresh, toggleAllLists, isSmallWidth, t }) => {
  const styles = {
    refresh: {
      marginRight: 10
    }
  }

  return !isSmallWidth ? (
    <div>
      <Button variant="contained" color="secondary" style={styles.refresh} onClick={() => refresh()}>
        <RefreshIcon />
        {t('refresh')}
      </Button>
      <Button variant="outlined" color="secondary" onClick={() => toggleAllLists(!allListsOpened)}>
        <MoreHorizIcon />
        {allListsOpened ? t('allClose') : t('allOpen')}
      </Button>
    </div>
  ) : (
    <div>
      <IconButton onClick={() => refresh()}>
        <RefreshIcon />
      </IconButton>
      <IconButton onClick={() => toggleAllLists(!allListsOpened)}>
        <MoreHorizIcon />
      </IconButton>
    </div>
  )
}

export default withTranslation()(ToggleButton)

/* PropTypes */
ToggleButton.propTypes = {
  allListsOpened: PropTypes.bool.isRequired,
  refresh: PropTypes.func.isRequired,
  toggleAllLists: PropTypes.func.isRequired,
  isSmallWidth: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired
}
