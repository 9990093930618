import React from 'react'
import PropTypes from 'prop-types'
import withWidth from '@material-ui/core/withWidth'
import { connect } from 'react-redux'
import { changeWidth } from '../api/actions'

const { useEffect } = React

// すべてのコンポーネントに共通するレイアウトを制御するためのコンポーネント
const WindowLayout = ({ width, changeWidth, children }) => {
  useEffect(
    () => {
      changeWidth({ width })
    },
    [width]
  )

  return <div>{children}</div>
}

export default connect(
  null,
  { changeWidth }
)(withWidth()(WindowLayout))

WindowLayout.propTypes = {
  changeWidth: PropTypes.func.isRequired,
  width: PropTypes.string.isRequired
}
