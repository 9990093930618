/* eslint-disable import/no-mutable-exports, no-undef */
import * as config from './config'

export let DOMAIN = config.domain
export let BASE = config.base
export let IDENTITY_POOL_ID = config.identityPoolId
export let REGION = config.region
export let API_ENDPOINT = config.apiEndpoint
export let API_KEY = config.apiKey

export const COLOR = {
  PRIMARY: '#008C00',
  SECONDARY: '#26A69A',
  POSITIVE: '#21BA45',
  TERITARY: '#c0e1dc',
  WHITE: '#f7f7f7',
  LIGHT: '#e6e6e6',
  BLACK: '#232323',
  NEGATIVE: '#DB2828',
  GRAY: '#777',
  STRONG_GRAY: '#555',
  WEEK_GRAY: '#bdbdbd'
}

export const CONTENT_VIEW_LIMIT = 10000000
