import React from 'react'
import PropTypes from 'prop-types'
import AppBar from '@material-ui/core/AppBar'
import ToolBar from '@material-ui/core/Toolbar'
import Button from '@material-ui/core/Button'
import { withTranslation } from 'react-i18next'

const ContentView = ({ uri, title, handleClose, t }) => {
  const styles = {
    wrap: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%'
    },
    toolBar: { justifyContent: 'space-between' },
    button: { textTransform: 'initial' },
    iframe: { flexGrow: 1, width: '100%' }
  }

  return (
    <div style={styles.wrap}>
      <AppBar position="static" color="secondary">
        <ToolBar style={styles.toolBar}>
          {title}
          <Button variant="raised" color="primary" onClick={handleClose} style={styles.button}>
            {t('close')}
          </Button>
        </ToolBar>
      </AppBar>
      <iframe style={styles.iframe} src={uri} />
    </div>
  )
}

export default withTranslation()(ContentView)

/* PropTypes */
ContentView.propTypes = {
  uri: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
}
