import * as R from 'ramda'
import db from './database'

const tableName = 'CONTENT'

const findContentById = id => {
  const params = {
    IndexName: 'id-index',
    KeyConditionExpression: '#id = :id',
    ExpressionAttributeNames: { '#id': 'id' },
    ExpressionAttributeValues: { ':id': id },
    ScanIndexForward: false
  }

  return db.getItem(tableName, params)
}

const addCount = (id, num) => {
  return findContentById(id)
    .then(R.prop('owner'))
    .then(owner => db.addNumOfSomeAttribute(tableName, { owner, id }, 'count', num))
}

export default {
  findContentById,
  addCount
}
