import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import { withTranslation } from 'react-i18next'
import '../../i18n'

const OriginalDialog = ({
  title,
  text,
  open,
  cancel = false,
  okButtonText,
  cancelButtonText,
  onCancel,
  onClick,
  maxWidth = 'sm',
  fullWidth = false,
  titleStyle,
  textStyle,
  t
}) => {
  const styles = {
    button: { textTransform: 'initial' }
  }

  return (
    <Dialog open={open} maxWidth={maxWidth} fullWidth={fullWidth}>
      {title ? (
        <DialogTitle>
          <span style={titleStyle}>{title}</span>
        </DialogTitle>
      ) : null}

      {text ? (
        <DialogContent>
          <DialogContentText>
            <span style={textStyle}>{text}</span>
          </DialogContentText>
        </DialogContent>
      ) : null}

      <DialogActions>
        {cancel ? (
          <Button onClick={onCancel} color="primary" style={styles.button}>
            {cancelButtonText || t('cancel')}
          </Button>
        ) : null}

        <Button variant="raised" onClick={onClick} color="primary" style={styles.button}>
          {okButtonText || t('ok')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default withTranslation()(OriginalDialog)

OriginalDialog.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  open: PropTypes.bool.isRequired,
  cancel: PropTypes.bool,
  okButtonText: PropTypes.string,
  cancelButtonText: PropTypes.string,
  onCancel: PropTypes.func,
  onClick: PropTypes.func,
  maxWidth: PropTypes.string,
  fullWidth: PropTypes.bool,
  titleStyle: PropTypes.object,
  textStyle: PropTypes.object,
  t: PropTypes.func.isRequired
}
