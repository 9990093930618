import * as R from 'ramda'
import db from './database'

const tableName = 'TEST_RESULT'

const findTestResultByContentIdAndStaffId = (contentId, staffId) => {
  const params = {
    IndexName: 'cid-sid-index',
    KeyConditionExpression: '#c = :c AND #s = :s',
    ExpressionAttributeNames: {
      '#c': 'cid',
      '#s': 'sid'
    },
    ExpressionAttributeValues: {
      ':c': contentId,
      ':s': staffId
    }
  }

  return db.fetchItems({ table: tableName, params }).then(R.prop('items'))
}

export default {
  findTestResultByContentIdAndStaffId
}
