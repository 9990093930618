import React from 'react'
import PropTypes from 'prop-types'
import { Redirect, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { updateStaffsLanguage, selectLanguage } from '../api/actions'
import i18n from '../i18n'

const { useEffect } = React

const Auth = ({
  profile,
  isAuthenticated,
  isLanguageSelected,
  updateStaffsLanguage,
  selectLanguage,
  location,
  children
}) => {
  useEffect(() => {
    // ログインページでユーザーが言語を選択した時（isLanguageSelectedがtrueの時）のみ、言語の更新処理をかける
    // スタッフ情報を取得する前に、updateStaffsLanguageの処理が走るとエラーになるので、if文の条件に必ずisAuthenticatedを入れること
    if (isAuthenticated && isLanguageSelected) updateStaffsLanguage({ lang: i18n.language })
    else if (isAuthenticated) selectLanguage({ lang: profile.lang })
    // else console.log(profile.lang)
  }, [])

  return isAuthenticated ? children : <Redirect to={'/' + location.search} />
}

const mapStateToProps = state => ({
  profile: state.profile,
  isAuthenticated: state.isAuthenticated,
  isLanguageSelected: state.isLanguageSelected
})

export default withRouter(
  connect(
    mapStateToProps,
    { updateStaffsLanguage, selectLanguage }
  )(Auth)
)

/* PropTypes */
Auth.propTypes = {
  profile: PropTypes.object.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  isLanguageSelected: PropTypes.bool.isRequired,
  updateStaffsLanguage: PropTypes.func.isRequired,
  selectLanguage: PropTypes.func.isRequired
}
