import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Button from '@material-ui/core/Button'
import CheckIcon from '@material-ui/icons/Check'
import Chip from '@material-ui/core/Chip'
import { withTranslation } from 'react-i18next'
import * as R from 'ramda'
import Dialog from '../Common/Dialog'
import { COLOR } from '../../constant'
import Icon from './ContentIcon'

const { useState } = React

const ContentText = ({ title, message }) => {
  const styles = {
    title: {
      whiteSpace: 'pre-line',
      overflowWrap: 'break-word'
    },
    message: {
      whiteSpace: 'pre-line',
      overflowWrap: 'break-word',
      color: COLOR.STRONG_GRAY
    }
  }

  return (
    <ListItemText>
      <Typography variant="subheading" style={styles.title}>
        {title}
      </Typography>
      <Typography style={styles.message}>{message}</Typography>
    </ListItemText>
  )
}

const TestTaken = ({ t }) => {
  const styles = {
    taken: {
      color: COLOR.SECONDARY,
      border: 'solid 1px',
      borderColor: COLOR.SECONDARY,
      borderRadius: 5,
      padding: 10,
      display: 'flex',
      alignItems: 'center'
    }
  }

  return (
    <div style={styles.taken}>
      <CheckIcon />
      {t('taken')}
    </div>
  )
}

const ConfirmButton = ({ hasConfirmed, isPending, isArchive, onConfirm, t }) => {
  const styles = {
    button: { color: COLOR.SECONDARY }
  }

  return hasConfirmed ? (
    <Button variant="outlined" disabled color="secondary" style={styles.button}>
      <CheckIcon />
      {t('viewed')}
    </Button>
  ) : isPending || isArchive ? null : (
    <Button variant="contained" color="secondary" onClick={onConfirm}>
      {t('mark')}
    </Button>
  )
}

const HasAnswered = ({ t }) => {
  const styles = {
    button: { color: COLOR.SECONDARY }
  }

  return (
    <Button variant="outlined" disabled color="secondary" style={styles.button}>
      <CheckIcon />
      {t('answered')}
    </Button>
  )
}

const TestStatus = ({ score, maxScore, isPassed, t }) => {
  const styles = {
    wrapper: {
      display: 'flex',
      justifyContent: 'flex-end',
      paddingRight: 0
    },
    chip: {
      color: 'white',
      marginRight: 5
    },
    score: {
      backgroundColor: COLOR.SECONDARY
    },
    status: {
      backgroundColor: isPassed ? COLOR.PRIMARY : COLOR.NEGATIVE
    }
  }

  const chipLabel = isPassed ? t('passed') : t('failed')

  return (
    <ListItemText style={styles.wrapper}>
      <Chip label={`${score} / ${maxScore}`} style={{ ...styles.chip, ...styles.score }} />
      <Chip label={chipLabel} style={{ ...styles.chip, ...styles.status }} />
    </ListItemText>
  )
}

const ContentItem = ({ content, isPending, isArchive, hasConfirmed, openContent, onConfirm, isSmallWidth, classes, t }) => {
  const [messageDialogOpen, toggleMessageDialog] = useState(false)
  const [examDialogOpen, toggleExamDialog] = useState(false)

  const { id, title, message, purpose, sco, max, pass, showResult } = content
  const isPassed = pass === 'passed'

  const isTestContent = purpose === 'exercise' || purpose === 'exam'
  const isThinq = purpose === 'exercise' || purpose === 'exam' || purpose === 'questionnaire'
  const isExercise = purpose === 'exercise'
  const isQuestionnaire = purpose === 'questionnaire'

  const displaysConfirmButton = !isThinq
  const hasTestTaken = hasConfirmed && !R.isNil(sco)
  const displaysTestStatus = isExercise || showResult

  const onClick = () => {
    if (purpose === 'exam') return toggleExamDialog(true)
    if (purpose !== 'pdf') return toggleMessageDialog(true)
    return openContent(id)
  }

  const onConfirmClick = event => {
    event.stopPropagation()
    onConfirm(id)
  }

  const styles = {
    listItem: {
      flexDirection: isSmallWidth ? 'column' : 'row',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      borderTop: 'solid 1px #e6e6e6',
      borderLeft: 'solid 1px #e6e6e6',
      borderRight: 'solid 1px #e6e6e6'
    },
    main: {
      display: 'flex',
      flexDirection: 'row',
      flex: isSmallWidth ? null : 1,
      alignItems: 'center',
      marginBottom: isSmallWidth ? 10 : 0,
      width: '100%'
    },
    sub: {
      width: 160,
      alignSelf: isSmallWidth ? 'flex-end' : 'center',
      display: 'flex',
      justifyContent: 'flex-end'
    }
  }

  return (
    <div>
      <ListItem button style={styles.listItem} classes={{ button: classes.listItemRoot }} disabled={isPending} onClick={onClick}>
        <div style={styles.main}>
          <Icon purpose={purpose} />
          <ContentText title={title} message={message} />
        </div>

        <div style={styles.sub}>
          {displaysConfirmButton ? (
            <ConfirmButton hasConfirmed={hasConfirmed} isPending={isPending} isArchive={isArchive} onConfirm={onConfirmClick} t={t} />
          ) : null}

          {isQuestionnaire && hasConfirmed ? <HasAnswered t={t} /> : null}

          {isTestContent && hasTestTaken ? (
            displaysTestStatus ? (
              <TestStatus score={sco} maxScore={max} isPassed={isPassed} t={t} />
            ) : (
              <TestTaken t={t} />
            )
          ) : null}
        </div>
      </ListItem>

      {/* 試験を受ける前の注意 */}
      <Dialog
        title={t('examAlertTitle')}
        text={hasConfirmed ? t('viewedExamAlert') : t('examAlertMessage')}
        open={examDialogOpen}
        cancel
        onCancel={() => toggleExamDialog(false)}
        onClick={() => {
          toggleExamDialog(false)
          toggleMessageDialog(true)
        }}
      />

      {/* 開始メッセージ */}
      <Dialog
        text={message}
        open={messageDialogOpen}
        cancel
        onCancel={() => toggleMessageDialog(false)}
        onClick={() => {
          toggleMessageDialog(false)
          openContent(id)
        }}
      />
    </div>
  )
}

const styles = () => ({
  listItemRoot: {
    '&:hover': {
      backgroundColor: '#EAFAF8'
    }
  }
})

export default withTranslation()(withStyles(styles)(ContentItem))

/* PropTypes */
ContentText.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired
}

TestTaken.propTypes = {
  t: PropTypes.func.isRequired
}

ConfirmButton.propTypes = {
  hasConfirmed: PropTypes.bool.isRequired,
  isPending: PropTypes.bool.isRequired,
  isArchive: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func,
  t: PropTypes.func.isRequired
}

HasAnswered.propTypes = {
  t: PropTypes.func.isRequired
}

TestStatus.propTypes = {
  score: PropTypes.number,
  maxScore: PropTypes.number,
  isPassed: PropTypes.bool,
  t: PropTypes.func.isRequired
}

ContentItem.propTypes = {
  content: PropTypes.object.isRequired,
  isPending: PropTypes.bool.isRequired,
  isArchive: PropTypes.bool.isRequired,
  hasConfirmed: PropTypes.bool.isRequired,
  openContent: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  isSmallWidth: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired
}
