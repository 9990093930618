import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import Chip from '@material-ui/core/Chip'
import ListItemText from '@material-ui/core/ListItemText'
import Collapse from '@material-ui/core/Collapse'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import CheckIcon from '@material-ui/icons/Check'
import moment from 'moment'
import { withTranslation } from 'react-i18next'
import * as R from 'ramda'
import Content from '../../pages/Content'
import { COLOR } from '../../constant'

const DeliveryText = ({ title, status, start, end, expired, isSentTabSelected, t }) => {
  const styles = {
    text: {
      display: 'flex',
      alignItems: 'center',
      whiteSpace: 'pre-line',
      overflowWrap: 'break-word',
      color: COLOR.BLACK,
      marginBottom: 5
    },
    title: {
      fontSize: '1.1em'
    },
    chip: {
      backgroundColor: COLOR.WHITE,
      height: 20,
      marginRight: 10
    },
  }

  return (
    <ListItemText>
      <Typography variant="subheading" style={styles.text}>
        {status === 'pending' ? <Chip label={t('pending')} style={styles.chip} /> : null}
        <div style={styles.title}>{title}</div>
      </Typography>

      {isSentTabSelected ? (
        <Typography style={styles.text}>
          {`${t('period')} ${moment(start).format('YYYY/MM/DD HH:mm')} 〜 ${moment(end).format('YYYY/MM/DD HH:mm')}`}
        </Typography>
      ) : (
        <Typography style={styles.text}>
          {`${t('expiredDate')} ${expired ? moment(expired).format('YYYY/MM/DD HH:mm') : t('neverExpire')}`}
        </Typography>
      )}
    </ListItemText>
  )
}

const ToggleButton = ({ isListOpened, toggleList, isSmallWidth, t }) => {
  const styles = {
    button: {
      color: COLOR.STRONG_GRAY
    }
  }
  return !isSmallWidth ? (
    <Button variant="outlined" onClick={() => toggleList(!isListOpened)} style={styles.button}>
      {isListOpened ? t('close') : t('open')}
    </Button>
  ) : (
    <IconButton onClick={() => toggleList(!isListOpened)} style={styles.button}>
      {isListOpened ? <ExpandLessIcon /> : <ExpandMoreIcon />}
    </IconButton>
  )
}

const DeliveryItem = ({ delivery, isSentTabSelected, isListOpened, toggleList, isSmallWidth, t }) => {
  const { id, title, start, end, expired, status, contents } = delivery

  const showsConfirmedMark = delivery.viewed
  const hasContentFetched = R.all(R.complement(R.isNil), contents)

  const styles = {
    item: {
      marginBottom: 30
    },
    main: {
      display: 'flex',
      flexDirection: 'row',
      flex: isSmallWidth ? null : 1,
      alignItems: 'center',
      padding: isSmallWidth ? '10 0 10 20' : '10 20',
      backgroundColor: showsConfirmedMark || !isSentTabSelected ? COLOR.LIGHT : COLOR.TERITARY
    }
  }

  return (
    <div style={styles.item}>
      <div style={styles.main}>
        {showsConfirmedMark ? <CheckIcon /> : null}
        <DeliveryText title={title} status={status} start={start} end={end} expired={expired} isSentTabSelected={isSentTabSelected} t={t} />
        <ToggleButton isListOpened={isListOpened} toggleList={toggleList} isSmallWidth={isSmallWidth} t={t} />
      </div>
      {hasContentFetched ? (
        <Collapse in={isListOpened} timeout="auto" unmountOnExit>
          <Content deliveryId={id} status={status} contents={contents} />
        </Collapse>
      ) : null}
    </div>
  )
}

export default withTranslation()(DeliveryItem)

DeliveryText.propTypes = {
  title: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  start: PropTypes.string.isRequired,
  end: PropTypes.string.isRequired,
  expired: PropTypes.string,
  isSentTabSelected: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired
}

ToggleButton.propTypes = {
  isListOpened: PropTypes.bool.isRequired,
  toggleList: PropTypes.func.isRequired,
  isSmallWidth: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired
}

DeliveryItem.propTypes = {
  delivery: PropTypes.object.isRequired,
  isSentTabSelected: PropTypes.bool.isRequired,
  isListOpened: PropTypes.bool.isRequired,
  toggleList: PropTypes.func.isRequired,
  isSmallWidth: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired
}
