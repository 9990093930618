import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { updateStaffsLanguage, confirm } from '../../api/actions'
import Dialog from '../Common/Dialog'
import i18n from '../../i18n'

const { useState, useEffect } = React

const UserConfirmDialog = ({ name, isConfirmed, isLanguageSelected, updateStaffsLanguage, confirm, t }) => {
  const [dialogOpen, toggleDialog] = useState(false)

  useEffect(() => {
    // まだ本人確認ダイアログのOKを押していないときだけ、ダイアログを表示
    // こうしないと、QRページから戻ってくる度にダイアログが開く
    if (!isConfirmed) toggleDialog(true)
  }, [])

  const onClick = async () => {
    // ユーザーがログインページで言語の選択を行っていた場合、OKボタンを押した時点でDBを更新
    if (isLanguageSelected) await updateStaffsLanguage({ lang: i18n.language })
    await confirm()
    toggleDialog(false)
  }

  const onCancel = () => {
    // sotreをクリアするために、react-routerではなく、window.locationで遷移
    window.location.href = '/'
  }

  // {name}の部分を、実際のスタッフ名に置き換える
  const confirmTitle = t('confirmTitle')
  const confirmMessage = t('confirmMessage')
  const title = confirmTitle.replace('{name}', name)
  const message = confirmMessage.replace('{name}', name)

  return (
    <Dialog
      title={title}
      text={message}
      open={dialogOpen}
      cancel={true}
      cancelButtonText={t('exit')}
      onClick={onClick}
      onCancel={onCancel}
    />
  )
}

const mapStateToProps = state => ({
  name: state.profile.name,
  isConfirmed: state.isConfirmed,
  isLanguageSelected: state.isLanguageSelected
})

export default withRouter(
  connect(
    mapStateToProps,
    { updateStaffsLanguage, confirm }
  )(withTranslation()(UserConfirmDialog))
)

/* PropTypes */
UserConfirmDialog.propTypes = {
  name: PropTypes.string.isRequired,
  isConfirmed: PropTypes.bool.isRequired,
  isLanguageSelected: PropTypes.bool.isRequired,
  updateStaffsLanguage: PropTypes.func.isRequired,
  confirm: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
}
