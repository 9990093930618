import React from 'react'
import PropTypes from 'prop-types'
import MenuItem from '@material-ui/core/MenuItem'
import i18n, { langs } from '../../i18n'

const LanguageItems = ({ onClick }) => {
  const styles = {
    item: { borderBottom: 'solid 1px #ececec' }
  }

  return langs.map(lang => (
    <MenuItem
      key={lang.value}
      selected={lang.value === i18n.language}
      onClick={() => onClick(lang.value)}
      style={styles.item}
    >
      {lang.label}
    </MenuItem>
  ))
}

export default LanguageItems

LanguageItems.propTypes = {
  onClick: PropTypes.func.isRequired
}
